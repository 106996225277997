export let langArr = {};
langArr.en = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Bonus round does not contribute to a jackpot fund. During bonus round jackpot cannot be won.',
'bonus_rounds_p5': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
};
langArr.sw = {
'loading': 'Inapakia',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Raundi za Bonasi",
  "bonus_rounds_p1": "Mchezaji anaweza kutuzwa bila mpangilio na raundi za bonasi (yaani dau za bure). Mchoro ibukizi utaonekana kwenye skrini ukimjulisha mchezaji kuhusu raundi za bonasi zilizotolewa, idadi ya dau na kiasi kwa kila dau.",
  "bonus_rounds_p2": "Mara tu zinapotunukiwa mchezaji anaweza kuchagua wakati wa kuanza mzunguko wa bonasi. Mara tu duru za bonasi zinapoanzishwa, dau kiotomatiki na dau lililobainishwa huwekwa, na ni juu ya mchezaji kutoa pesa wakati wowote.",
  "bonus_rounds_p3": "Mizunguko ya bonasi inaweza kuwa na kikomo cha muda, baada ya hapo inakuwa batili na isiyoweza kutumika. Kipindi cha uhalali, ikiwa kipo, kinaonyeshwa chini ya mzunguko wa bonasi.",
  "bonus_rounds_p4": "Raundi ya bonasi haichangii hazina ya jackpot. Wakati wa bonasi raundi ya jackpot haiwezi kushinda.",
  "bonus_rounds_p5": "Uvivu wa mchezaji unaweza kusababisha kughairiwa kwa raundi ya bonasi inayoendelea. Katika kesi hii, nambari ya raundi za bonasi hupunguzwa na salio la mchezaji hubaki sawa.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet tayari ipo hai',
};
langArr.zu = {
'loading': 'Iyalayisha',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Ibhonasi Imijikelezo",
"bonus_rounds_p1": "Umdlali angase aklonyeliswe ngokungahleliwe ngemizuliswano yebhonasi (okungukuthi ukubheja kwamahhala). Isithombe esizivelelayo sizovela esikrinini sazisa umdlali mayelana nemizuliswano yebhonasi eklonyelisiwe, inombolo yokubheja kanye nenani lokubheja ngakunye.",
"bonus_rounds_p2": "Uma sebeklonyelisiwe umdlali angase akhethe ukuthi uwuqala nini umjikelezo webhonasi. Uma imizuliswano yebhonasi isiqalile, ukubheja okuzenzakalelayo nesiteki esishiwo kubekwa, futhi kukumdlali ukuthi akhiphe imali nganoma yisiphi isikhathi.",
"bonus_rounds_p3": "Imizuliswano yebhonasi ingaba nomkhawulo wesikhathi, ngemva kwalokho iba yize futhi ingasebenziseki. Isikhathi sokuqinisekisa, uma sikhona, siboniswa ngaphansi komjikelezo webhonasi.",
"bonus_rounds_p4": "Umjikelezo webhonasi awufaki isandla esikhwameni se-jackpot. Ngesikhathi se-jackpot eyindilinga yebhonasi ayikwazi ukunqotshwa.",
"bonus_rounds_p5": "Ukungenzi lutho komdlali kungase kuphumele ekukhanselweni komjikelezo webhonasi osebenzayo. Kulokhu, inombolo yemizuliswano yebhonasi yehlisiwe futhi ibhalansi yomdlali ihlala injalo.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Ukubheja sekukhona',
};
langArr.xh = {
'loading': 'Iyalowuda',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Imijikelo yebhonasi",
"bonus_rounds_p1": "Umdlali unokunikwa imijikelo yebhonasi ngokungakhethiyo (okt ukubheja simahla). Umzobo ozivelelayo uya kuvela kwisikrini ukwazisa umdlali malunga nokunikwa kwemijikelo yebhonasi, inani lokubheja kunye nemali yokubheja.",
"bonus_rounds_p2": "Nje ukuba bawongwe, umdlali usenokukhetha ukuqala nini umjikelo webhonasi. Xa imijikelo yebhonasi iqalisiwe, ukubheja okuzenzekelayo kunye nesibonda esichaziweyo kubekwe, kwaye kuxhomekeke kumdlali ukuba akhuphe imali nangawuphi na umzuzu.",
"bonus_rounds_p3": "Imijikelo yebhonasi inokuba nomda wexesha, emva koko iba lilize kwaye ingasebenziseki. Ixesha lokuqinisekisa, ukuba likhona, liboniswa ngaphantsi komjikelo webhonasi.",
"bonus_rounds_p4": "Umjikelo webhonasi awufaki galelo kwingxowa-mali yejackpot. Ngexesha ibhonasi jackpot ngeenxa zonke ayinakuphumelela.",
"bonus_rounds_p5": "Ukungenzi nto komdlali kunokubangela ukurhoxiswa komjikelo webhonasi osebenzayo. Kulo mzekelo, ibhonasi imijikelo inani liyehla kwaye ibhalansi umdlali uhlala efanayo.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Ukubheja sele kusebenzile',
};
langArr.af = {
'loading': 'Laai',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Bonus rondtes",
"bonus_rounds_p1": "&#39;n Speler kan lukraak met bonusrondtes (dws gratis weddenskappe) toegeken word. &#39;n Opspringgrafika sal op die skerm verskyn wat die speler in kennis stel van toegekende bonusrondtes, aantal weddenskappe en bedrag per weddenskap.",
"bonus_rounds_p2": "Sodra hulle toegeken is, kan speler kies wanneer om die bonusronde te begin. Sodra die bonusrondtes begin is, word outomatiese weddenskap met die gespesifiseerde belang geplaas, en dit is aan die speler om op enige gegewe oomblik uit te betaal.",
"bonus_rounds_p3": "Bonusrondtes kan &#39;n tydsbeperking h\u00ea, waarna dit nietig en onbruikbaar word. Die geldigheidstydperk, indien enige, word onder die bonusronde vertoon.",
"bonus_rounds_p4": "Bonusronde dra nie by tot &#39;n boerpotfonds nie. Tydens bonusronde kan boerpot nie gewen word nie.",
"bonus_rounds_p5": "Speler se ledigheid kan lei tot die kansellasie van &#39;n aktiewe bonusronde. In hierdie geval word die aantal bonusrondtes verminder en die speler se balans bly dieselfde.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Weddenskap reeds aktief',
};
langArr.so = {
'loading': 'Ea loada',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Wareegyada Gunnada",
"bonus_rounds_p1": "Ciyaaryahanka waxaa laga yaabaa in si aan kala sooc lahayn loogu abaalmariyo wareegyo gunno ah (ie sharad bilaash ah). Garaaf ka soo baxay ayaa ka soo muuqan doona shaashadda ogeysiinta ciyaaryahanka ku saabsan wareegyada gunnada la abaalmariyey, tirada sharadka iyo qaddarka sharad kasta.",
"bonus_rounds_p2": "Marka la siiyo ciyaartoyga waxaa laga yaabaa inuu doorto goorta la bilaabayo wareegga gunnada. Marka wareegyada gunnada la bilaabo, khamaar toos ah oo leh saamiga la cayimay ayaa la dhigayaa, waxayna ku xiran tahay ciyaartoyga inuu lacag ka bixiyo daqiiqad kasta.",
"bonus_rounds_p3": "Wareegyada gunnada waxay yeelan karaan waqti xaddidan, ka dib markaa waxay noqdaan wax madhan oo aan la isticmaali karin. Muddada ansaxinta, haddii ay jirto, waxa lagu soo bandhigay gunnada hoosteeda.",
"bonus_rounds_p4": "Wareegga gunnada waxba kuma biirin sanduuqa Ghanna. Inta lagu guda jiro bonus wareega Ghanna laguma guulaysan karo.",
"bonus_rounds_p5": "Ciyaar la&#39;aanta waxay keeni kartaa in la joojiyo wareega gunnada firfircoon. Xaaladdan oo kale, tirada wareegyada gunnada waa la dhimay oo dheelitirka ciyaartoygu wuu sii jirayaa.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Khadka khamarka horey ayuu u furan yahay',
};
langArr.tn = {
'loading': 'E a laisa',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Ditikologo tsa Bonase",
"bonus_rounds_p1": "Motshameki a ka nna a abelwa ka go sa rulaganngwa ditikologo tsa bonase (ke gore dibet\u0161he tsa mahala). A popup kerafo tla hlaha ka skrine tsebisa sebapali ka abeloeng bonase potoloho, palo ya bets le tjhelete ka bet.",
"bonus_rounds_p2": "Fa ba sena go abelwa motshameki a ka tlhopha gore o tla simolola leng tikologo ya bonase. Hang ha bonase potoloho e simolotse, jarolla ka ho iketsa becha le boletsweng set\u0161he e behilwe, &#39;me ho ho fihlela ho sebapali ho cashout ka nako efe kapa efe e itseng.",
"bonus_rounds_p3": "Ditikologo tsa bonase di ne di ka nna le nako e e beilweng, morago ga moo di nna lefela e bile di sa dirisiwe. Paka ya go dira, fa e le teng, e bontshiwa ka fa tlase ga tikologo ya bonase.",
"bonus_rounds_p4": "Tikologo ya bonase ga e tsenye letsogo mo letloleng la jackpot. Nakong bonase potoloha jackpot ke ke hl\u014dla.",
"bonus_rounds_p5": "Go sa dire sepe ga motshameki go ka felela ka gore tikologo ya bonase e e dirang e phimolwe. Tabeng ena, bonase ditikologo nomoro e fokotswa le sebapali tekatekano lula t\u0161oanang.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Mare baakanya a ntse a le teng',
};
langArr.ar = {
'loading': 'جاري التحميل',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u062c\u0648\u0644\u0627\u062a \u0627\u0644\u0645\u0643\u0627\u0641\u0623\u0629",
"bonus_rounds_p1": "\u0642\u062f \u064a\u062d\u0635\u0644 \u0627\u0644\u0644\u0627\u0639\u0628 \u0639\u0644\u0649 \u062c\u0648\u0644\u0627\u062a \u0645\u0643\u0627\u0641\u0623\u0629 \u0639\u0634\u0648\u0627\u0626\u064a\u064b\u0627 (\u0623\u064a \u0631\u0647\u0627\u0646\u0627\u062a \u0645\u062c\u0627\u0646\u064a\u0629). \u0633\u062a\u0638\u0647\u0631 \u0646\u0627\u0641\u0630\u0629 \u0645\u0646\u0628\u062b\u0642\u0629 \u0639\u0644\u0649 \u0627\u0644\u0634\u0627\u0634\u0629 \u0644\u0625\u0639\u0644\u0627\u0645 \u0627\u0644\u0644\u0627\u0639\u0628 \u0628\u062c\u0648\u0644\u0627\u062a \u0627\u0644\u0645\u0643\u0627\u0641\u0623\u0629 \u0627\u0644\u0645\u0645\u0646\u0648\u062d\u0629 \u0648\u0639\u062f\u062f \u0627\u0644\u0631\u0647\u0627\u0646\u0627\u062a \u0648\u0627\u0644\u0645\u0628\u0644\u063a \u0644\u0643\u0644 \u0631\u0647\u0627\u0646.",
"bonus_rounds_p2": "\u0628\u0645\u062c\u0631\u062f \u062d\u0635\u0648\u0644 \u0627\u0644\u0644\u0627\u0639\u0628 \u0639\u0644\u0649 \u0627\u0644\u062c\u0648\u0627\u0626\u0632\u060c \u064a\u0645\u0643\u0646\u0647 \u0627\u062e\u062a\u064a\u0627\u0631 \u0645\u0648\u0639\u062f \u0628\u062f\u0621 \u062c\u0648\u0644\u0629 \u0627\u0644\u0645\u0643\u0627\u0641\u0623\u0629. \u0628\u0645\u062c\u0631\u062f \u0628\u062f\u0621 \u062c\u0648\u0644\u0627\u062a \u0627\u0644\u0645\u0643\u0627\u0641\u0623\u0629\u060c \u064a\u062a\u0645 \u0648\u0636\u0639 \u0631\u0647\u0627\u0646 \u062a\u0644\u0642\u0627\u0626\u064a \u0628\u0627\u0644\u0631\u0647\u0627\u0646 \u0627\u0644\u0645\u062d\u062f\u062f\u060c \u0648\u064a\u0639\u0648\u062f \u0627\u0644\u0623\u0645\u0631 \u0644\u0644\u0627\u0639\u0628 \u0641\u064a \u0635\u0631\u0641 \u0627\u0644\u0623\u0645\u0648\u0627\u0644 \u0641\u064a \u0623\u064a \u0648\u0642\u062a.",
"bonus_rounds_p3": "\u0642\u062f \u062a\u0643\u0648\u0646 \u0644\u0644\u062c\u0648\u0644\u0627\u062a \u0627\u0644\u0625\u0636\u0627\u0641\u064a\u0629 \u062d\u062f \u0632\u0645\u0646\u064a\u060c \u0648\u0628\u0639\u062f \u0630\u0644\u0643 \u062a\u0635\u0628\u062d \u0644\u0627\u063a\u064a\u0629 \u0648\u063a\u064a\u0631 \u0635\u0627\u0644\u062d\u0629 \u0644\u0644\u0627\u0633\u062a\u062e\u062f\u0627\u0645. \u064a\u062a\u0645 \u0639\u0631\u0636 \u0641\u062a\u0631\u0629 \u0627\u0644\u0635\u0644\u0627\u062d\u064a\u0629\u060c \u0625\u0646 \u0648\u062c\u062f\u062a\u060c \u0623\u0633\u0641\u0644 \u0627\u0644\u062c\u0648\u0644\u0629 \u0627\u0644\u0625\u0636\u0627\u0641\u064a\u0629.",
"bonus_rounds_p4": "\u0644\u0627 \u062a\u0633\u0627\u0647\u0645 \u062c\u0648\u0644\u0629 \u0627\u0644\u0645\u0643\u0627\u0641\u0623\u0629 \u0641\u064a \u0635\u0646\u062f\u0648\u0642 \u0627\u0644\u062c\u0627\u0626\u0632\u0629 \u0627\u0644\u0643\u0628\u0631\u0649. \u0644\u0627 \u064a\u0645\u0643\u0646 \u0627\u0644\u0641\u0648\u0632 \u0628\u0627\u0644\u062c\u0627\u0626\u0632\u0629 \u0627\u0644\u0643\u0628\u0631\u0649 \u0623\u062b\u0646\u0627\u0621 \u062c\u0648\u0644\u0629 \u0627\u0644\u0645\u0643\u0627\u0641\u0623\u0629.",
"bonus_rounds_p5": "\u0642\u062f \u064a\u0624\u062f\u064a \u0639\u062f\u0645 \u0646\u0634\u0627\u0637 \u0627\u0644\u0644\u0627\u0639\u0628 \u0625\u0644\u0649 \u0625\u0644\u063a\u0627\u0621 \u062c\u0648\u0644\u0629 \u0627\u0644\u0645\u0643\u0627\u0641\u0623\u0629 \u0627\u0644\u0646\u0634\u0637\u0629. \u0641\u064a \u0647\u0630\u0647 \u0627\u0644\u062d\u0627\u0644\u0629\u060c \u064a\u062a\u0645 \u062a\u0642\u0644\u064a\u0644 \u0639\u062f\u062f \u062c\u0648\u0644\u0627\u062a \u0627\u0644\u0645\u0643\u0627\u0641\u0623\u0629 \u0648\u064a\u0638\u0644 \u0631\u0635\u064a\u062f \u0627\u0644\u0644\u0627\u0639\u0628 \u0643\u0645\u0627 \u0647\u0648.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'الرهان مفعل بالفعل',
};
langArr.bg = {
'loading': 'Зареждане',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u0411\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u043e\u0432\u0435",
"bonus_rounds_p1": "\u0418\u0433\u0440\u0430\u0447 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043d\u0430\u0433\u0440\u0430\u0434\u0435\u043d \u043d\u0430 \u0441\u043b\u0443\u0447\u0430\u0435\u043d \u043f\u0440\u0438\u043d\u0446\u0438\u043f \u0441 \u0431\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u043e\u0432\u0435 (\u0442.\u0435. \u0431\u0435\u0437\u043f\u043b\u0430\u0442\u043d\u0438 \u0437\u0430\u043b\u043e\u0437\u0438). \u041d\u0430 \u0435\u043a\u0440\u0430\u043d\u0430 \u0449\u0435 \u0441\u0435 \u043f\u043e\u044f\u0432\u0438 \u0438\u0437\u0441\u043a\u0430\u0447\u0430\u0449\u0430 \u0433\u0440\u0430\u0444\u0438\u043a\u0430, \u043a\u043e\u044f\u0442\u043e \u0443\u0432\u0435\u0434\u043e\u043c\u044f\u0432\u0430 \u0438\u0433\u0440\u0430\u0447\u0430 \u0437\u0430 \u043f\u0440\u0438\u0441\u044a\u0434\u0435\u043d\u0438 \u0431\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u043e\u0432\u0435, \u0431\u0440\u043e\u0439 \u0437\u0430\u043b\u043e\u0437\u0438 \u0438 \u0441\u0443\u043c\u0430 \u043d\u0430 \u0437\u0430\u043b\u043e\u0433.",
"bonus_rounds_p2": "\u0421\u043b\u0435\u0434 \u043a\u0430\u0442\u043e \u0431\u044a\u0434\u0430\u0442 \u043d\u0430\u0433\u0440\u0430\u0434\u0435\u043d\u0438, \u0438\u0433\u0440\u0430\u0447\u044a\u0442 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435 \u043a\u043e\u0433\u0430 \u0434\u0430 \u0437\u0430\u043f\u043e\u0447\u043d\u0435 \u0431\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u0430. \u0421\u043b\u0435\u0434 \u043a\u0430\u0442\u043e \u0431\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u043e\u0432\u0435\u0442\u0435 \u0437\u0430\u043f\u043e\u0447\u043d\u0430\u0442, \u0441\u0435 \u043f\u043e\u0441\u0442\u0430\u0432\u044f \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u0435\u043d \u0437\u0430\u043b\u043e\u0433 \u0441 \u043f\u043e\u0441\u043e\u0447\u0435\u043d\u0438\u044f \u0437\u0430\u043b\u043e\u0433 \u0438 \u043e\u0442 \u0438\u0433\u0440\u0430\u0447\u0430 \u0437\u0430\u0432\u0438\u0441\u0438 \u0434\u0430 \u0442\u0435\u0433\u043b\u0438 \u0432\u044a\u0432 \u0432\u0441\u0435\u043a\u0438 \u0435\u0434\u0438\u043d \u043c\u043e\u043c\u0435\u043d\u0442.",
"bonus_rounds_p3": "\u0411\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u043e\u0432\u0435\u0442\u0435 \u043c\u043e\u0433\u0430\u0442 \u0434\u0430 \u0438\u043c\u0430\u0442 \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u0438\u0435 \u0432\u044a\u0432 \u0432\u0440\u0435\u043c\u0435\u0442\u043e, \u0441\u043b\u0435\u0434 \u043a\u043e\u0435\u0442\u043e \u0441\u0442\u0430\u0432\u0430\u0442 \u043d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u0438 \u0438 \u043d\u0435\u0438\u0437\u043f\u043e\u043b\u0437\u0432\u0430\u0435\u043c\u0438. \u0421\u0440\u043e\u043a\u044a\u0442 \u043d\u0430 \u0432\u0430\u043b\u0438\u0434\u043d\u043e\u0441\u0442, \u0430\u043a\u043e \u0438\u043c\u0430 \u0442\u0430\u043a\u044a\u0432, \u0441\u0435 \u043f\u043e\u043a\u0430\u0437\u0432\u0430 \u043f\u043e\u0434 \u0431\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u0430.",
"bonus_rounds_p4": "\u0411\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u044a\u0442 \u043d\u0435 \u0434\u043e\u043f\u0440\u0438\u043d\u0430\u0441\u044f \u0437\u0430 \u0434\u0436\u0430\u043a\u043f\u043e\u0442 \u0444\u043e\u043d\u0434. \u041f\u043e \u0432\u0440\u0435\u043c\u0435 \u043d\u0430 \u0431\u043e\u043d\u0443\u0441 \u043a\u0440\u044a\u0433 \u0434\u0436\u0430\u043a\u043f\u043e\u0442\u044a\u0442 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0441\u043f\u0435\u0447\u0435\u043b\u0435\u043d.",
"bonus_rounds_p5": "\u0411\u0435\u0437\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435\u0442\u043e \u043d\u0430 \u0438\u0433\u0440\u0430\u0447\u0430 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0434\u043e\u0432\u0435\u0434\u0435 \u0434\u043e \u0430\u043d\u0443\u043b\u0438\u0440\u0430\u043d\u0435 \u043d\u0430 \u0430\u043a\u0442\u0438\u0432\u0435\u043d \u0431\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434. \u0412 \u0442\u043e\u0437\u0438 \u0441\u043b\u0443\u0447\u0430\u0439 \u0431\u0440\u043e\u044f\u0442 \u043d\u0430 \u0431\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u043e\u0432\u0435\u0442\u0435 \u0441\u0435 \u043d\u0430\u043c\u0430\u043b\u044f\u0432\u0430 \u0438 \u0431\u0430\u043b\u0430\u043d\u0441\u044a\u0442 \u043d\u0430 \u0438\u0433\u0440\u0430\u0447\u0430 \u043e\u0441\u0442\u0430\u0432\u0430 \u0441\u044a\u0449\u0438\u044f\u0442.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Залогът вече е активен',
};
langArr.de = {
'loading': 'Lädt',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Bonusrunden",
"bonus_rounds_p1": "Ein Spieler kann zuf\u00e4llig Bonusrunden (z. B. Gratiswetten) erhalten. Auf dem Bildschirm wird eine Popup-Grafik angezeigt, die den Spieler \u00fcber die gew\u00e4hrten Bonusrunden, die Anzahl der Wetten und den Betrag pro Wette informiert.",
"bonus_rounds_p2": "Sobald die Boni gutgeschrieben wurden, kann der Spieler w\u00e4hlen, wann die Bonusrunde beginnen soll. Sobald die Bonusrunde gestartet ist, wird automatisch eine Wette mit dem angegebenen Einsatz platziert und es liegt am Spieler, zu jedem beliebigen Zeitpunkt auszuzahlen.",
"bonus_rounds_p3": "Bonusrunden k\u00f6nnen zeitlich begrenzt sein, nach deren Ablauf sie ung\u00fcltig und unbrauchbar werden. Die G\u00fcltigkeitsdauer (sofern vorhanden) wird unter der Bonusrunde angezeigt.",
"bonus_rounds_p4": "Die Bonusrunde tr\u00e4gt nicht zum Jackpot bei. W\u00e4hrend der Bonusrunde kann kein Jackpot gewonnen werden.",
"bonus_rounds_p5": "Unt\u00e4tigkeit des Spielers kann zur Stornierung einer aktiven Bonusrunde f\u00fchren. In diesem Fall wird die Anzahl der Bonusrunden verringert und das Guthaben des Spielers bleibt gleich.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Wette bereits aktiv',
};
langArr.el = {
'loading': 'Φόρτωση',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u0393\u03cd\u03c1\u03bf\u03b9 \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2",
"bonus_rounds_p1": "\u0388\u03bd\u03b1\u03c2 \u03c0\u03b1\u03af\u03ba\u03c4\u03b7\u03c2 \u03bc\u03c0\u03bf\u03c1\u03b5\u03af \u03bd\u03b1 \u03b1\u03c0\u03bf\u03bd\u03b5\u03bc\u03b7\u03b8\u03b5\u03af \u03c4\u03c5\u03c7\u03b1\u03af\u03b1 \u03bc\u03b5 \u03b3\u03cd\u03c1\u03bf\u03c5\u03c2 \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2 (\u03b4\u03b7\u03bb\u03b1\u03b4\u03ae \u03b4\u03c9\u03c1\u03b5\u03ac\u03bd \u03c3\u03c4\u03bf\u03b9\u03c7\u03ae\u03bc\u03b1\u03c4\u03b1). \u0388\u03bd\u03b1 \u03b1\u03bd\u03b1\u03b4\u03c5\u03cc\u03bc\u03b5\u03bd\u03bf \u03b3\u03c1\u03b1\u03c6\u03b9\u03ba\u03cc \u03b8\u03b1 \u03b5\u03bc\u03c6\u03b1\u03bd\u03b9\u03c3\u03c4\u03b5\u03af \u03c3\u03c4\u03b7\u03bd \u03bf\u03b8\u03cc\u03bd\u03b7 \u03c0\u03bf\u03c5 \u03b5\u03b9\u03b4\u03bf\u03c0\u03bf\u03b9\u03b5\u03af \u03c4\u03bf\u03bd \u03c0\u03b1\u03af\u03ba\u03c4\u03b7 \u03b3\u03b9\u03b1 \u03c4\u03bf\u03c5\u03c2 \u03b3\u03cd\u03c1\u03bf\u03c5\u03c2 \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2 \u03c0\u03bf\u03c5 \u03ad\u03c7\u03bf\u03c5\u03bd \u03b1\u03c0\u03bf\u03bd\u03b5\u03bc\u03b7\u03b8\u03b5\u03af, \u03c4\u03bf\u03bd \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc \u03c4\u03c9\u03bd \u03c3\u03c4\u03bf\u03b9\u03c7\u03b7\u03bc\u03ac\u03c4\u03c9\u03bd \u03ba\u03b1\u03b9 \u03c4\u03bf \u03c0\u03bf\u03c3\u03cc \u03b1\u03bd\u03ac \u03c3\u03c4\u03bf\u03af\u03c7\u03b7\u03bc\u03b1.",
"bonus_rounds_p2": "\u039c\u03cc\u03bb\u03b9\u03c2 \u03b1\u03c0\u03bf\u03bd\u03b5\u03bc\u03b7\u03b8\u03bf\u03cd\u03bd, \u03bf \u03c0\u03b1\u03af\u03ba\u03c4\u03b7\u03c2 \u03bc\u03c0\u03bf\u03c1\u03b5\u03af \u03bd\u03b1 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03b5\u03b9 \u03c0\u03cc\u03c4\u03b5 \u03b8\u03b1 \u03be\u03b5\u03ba\u03b9\u03bd\u03ae\u03c3\u03b5\u03b9 \u03c4\u03bf\u03bd \u03b3\u03cd\u03c1\u03bf \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2. \u039c\u03cc\u03bb\u03b9\u03c2 \u03be\u03b5\u03ba\u03b9\u03bd\u03ae\u03c3\u03bf\u03c5\u03bd \u03bf\u03b9 \u03b3\u03cd\u03c1\u03bf\u03b9 \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2, \u03c4\u03bf\u03c0\u03bf\u03b8\u03b5\u03c4\u03b5\u03af\u03c4\u03b1\u03b9 \u03b1\u03c5\u03c4\u03cc\u03bc\u03b1\u03c4\u03bf \u03c3\u03c4\u03bf\u03af\u03c7\u03b7\u03bc\u03b1 \u03bc\u03b5 \u03c4\u03bf \u03ba\u03b1\u03b8\u03bf\u03c1\u03b9\u03c3\u03bc\u03ad\u03bd\u03bf \u03c0\u03bf\u03bd\u03c4\u03ac\u03c1\u03b9\u03c3\u03bc\u03b1 \u03ba\u03b1\u03b9 \u03b5\u03bd\u03b1\u03c0\u03cc\u03ba\u03b5\u03b9\u03c4\u03b1\u03b9 \u03c3\u03c4\u03bf\u03bd \u03c0\u03b1\u03af\u03ba\u03c4\u03b7 \u03bd\u03b1 \u03ba\u03ac\u03bd\u03b5\u03b9 \u03b5\u03be\u03b1\u03c1\u03b3\u03cd\u03c1\u03c9\u03c3\u03b7 \u03b1\u03bd\u03ac \u03c0\u03ac\u03c3\u03b1 \u03c3\u03c4\u03b9\u03b3\u03bc\u03ae.",
"bonus_rounds_p3": "\u039f\u03b9 \u03b3\u03cd\u03c1\u03bf\u03b9 \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2 \u03b8\u03b1 \u03bc\u03c0\u03bf\u03c1\u03bf\u03cd\u03c3\u03b1\u03bd \u03bd\u03b1 \u03ad\u03c7\u03bf\u03c5\u03bd \u03ad\u03bd\u03b1 \u03c7\u03c1\u03bf\u03bd\u03b9\u03ba\u03cc \u03cc\u03c1\u03b9\u03bf, \u03bc\u03b5\u03c4\u03ac \u03c4\u03bf \u03bf\u03c0\u03bf\u03af\u03bf \u03ba\u03b1\u03b8\u03af\u03c3\u03c4\u03b1\u03bd\u03c4\u03b1\u03b9 \u03ac\u03ba\u03c5\u03c1\u03bf\u03b9 \u03ba\u03b1\u03b9 \u03ac\u03c7\u03c1\u03b7\u03c3\u03c4\u03bf\u03b9. \u0397 \u03c0\u03b5\u03c1\u03af\u03bf\u03b4\u03bf\u03c2 \u03b9\u03c3\u03c7\u03cd\u03bf\u03c2, \u03b5\u03ac\u03bd \u03c5\u03c0\u03ac\u03c1\u03c7\u03b5\u03b9, \u03b5\u03bc\u03c6\u03b1\u03bd\u03af\u03b6\u03b5\u03c4\u03b1\u03b9 \u03ba\u03ac\u03c4\u03c9 \u03b1\u03c0\u03cc \u03c4\u03bf\u03bd \u03b3\u03cd\u03c1\u03bf \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2.",
"bonus_rounds_p4": "\u039f \u03b3\u03cd\u03c1\u03bf\u03c2 \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2 \u03b4\u03b5\u03bd \u03c3\u03c5\u03bd\u03b5\u03b9\u03c3\u03c6\u03ad\u03c1\u03b5\u03b9 \u03c3\u03b5 \u03ad\u03bd\u03b1 \u03c4\u03b1\u03bc\u03b5\u03af\u03bf \u03c4\u03b6\u03ac\u03ba\u03c0\u03bf\u03c4. \u039a\u03b1\u03c4\u03ac \u03c4\u03b7 \u03b4\u03b9\u03ac\u03c1\u03ba\u03b5\u03b9\u03b1 \u03c4\u03bf\u03c5 \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2 \u03b3\u03cd\u03c1\u03bf\u03c5 \u03c4\u03b6\u03ac\u03ba\u03c0\u03bf\u03c4 \u03b4\u03b5\u03bd \u03bc\u03c0\u03bf\u03c1\u03b5\u03af \u03bd\u03b1 \u03ba\u03b5\u03c1\u03b4\u03b7\u03b8\u03b5\u03af.",
"bonus_rounds_p5": "\u0397 \u03b1\u03b4\u03c1\u03ac\u03bd\u03b5\u03b9\u03b1 \u03c4\u03bf\u03c5 \u03c0\u03b1\u03af\u03ba\u03c4\u03b7 \u03bc\u03c0\u03bf\u03c1\u03b5\u03af \u03bd\u03b1 \u03ad\u03c7\u03b5\u03b9 \u03c9\u03c2 \u03b1\u03c0\u03bf\u03c4\u03ad\u03bb\u03b5\u03c3\u03bc\u03b1 \u03c4\u03b7\u03bd \u03b1\u03ba\u03cd\u03c1\u03c9\u03c3\u03b7 \u03b5\u03bd\u03cc\u03c2 \u03b5\u03bd\u03b5\u03c1\u03b3\u03bf\u03cd \u03b3\u03cd\u03c1\u03bf\u03c5 \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2. \u03a3\u03b5 \u03b1\u03c5\u03c4\u03ae\u03bd \u03c4\u03b7\u03bd \u03c0\u03b5\u03c1\u03af\u03c0\u03c4\u03c9\u03c3\u03b7, \u03bf \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc\u03c2 \u03c4\u03c9\u03bd \u03b3\u03cd\u03c1\u03bf\u03c5\u03c2 \u03bc\u03c0\u03cc\u03bd\u03bf\u03c5\u03c2 \u03bc\u03b5\u03b9\u03ce\u03bd\u03b5\u03c4\u03b1\u03b9 \u03ba\u03b1\u03b9 \u03c4\u03bf \u03c5\u03c0\u03cc\u03bb\u03bf\u03b9\u03c0\u03bf \u03c4\u03bf\u03c5 \u03c0\u03b1\u03af\u03ba\u03c4\u03b7 \u03c0\u03b1\u03c1\u03b1\u03bc\u03ad\u03bd\u03b5\u03b9 \u03c4\u03bf \u03af\u03b4\u03b9\u03bf.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Το στοίχημα είναι ήδη ενεργό',
};
langArr.es = {
'loading': 'Cargando',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Rondas de bonificaci\u00f3n",
"bonus_rounds_p1": "Un jugador puede recibir rondas de bonificaci\u00f3n (es decir, apuestas gratis) de forma aleatoria. Aparecer\u00e1 un gr\u00e1fico emergente en la pantalla que notificar\u00e1 al jugador sobre las rondas de bonificaci\u00f3n otorgadas, la cantidad de apuestas y el monto por apuesta.",
"bonus_rounds_p2": "Una vez que se otorgan, el jugador puede elegir cu\u00e1ndo comenzar la ronda de bonificaci\u00f3n. Una vez que comienza la ronda de bonificaci\u00f3n, se realiza una apuesta autom\u00e1tica con la cantidad especificada y el jugador puede retirar su dinero en cualquier momento.",
"bonus_rounds_p3": "Las rondas de bonificaci\u00f3n pueden tener un l\u00edmite de tiempo, despu\u00e9s del cual se vuelven nulas e inutilizables. El per\u00edodo de validez, si lo hay, se muestra debajo de la ronda de bonificaci\u00f3n.",
"bonus_rounds_p4": "La ronda de bonificaci\u00f3n no contribuye al fondo del premio mayor. Durante la ronda de bonificaci\u00f3n no se puede ganar el premio mayor.",
"bonus_rounds_p5": "La inactividad del jugador puede provocar la cancelaci\u00f3n de una ronda de bonificaci\u00f3n activa. En este caso, la cantidad de rondas de bonificaci\u00f3n se reduce y el saldo del jugador permanece igual.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Apuesta ya activa',
};
langArr.fr = {
'loading': 'Chargement',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Tours bonus",
"bonus_rounds_p1": "Un joueur peut se voir attribuer des tours bonus al\u00e9atoires (c&#39;est-\u00e0-dire des paris gratuits). Un graphique contextuel appara\u00eetra \u00e0 l&#39;\u00e9cran pour informer le joueur des tours bonus attribu\u00e9s, du nombre de paris et du montant par pari.",
"bonus_rounds_p2": "Une fois les points attribu\u00e9s, le joueur peut choisir le moment o\u00f9 il souhaite lancer le tour bonus. Une fois le tour bonus lanc\u00e9, un pari automatique avec la mise sp\u00e9cifi\u00e9e est plac\u00e9 et il appartient au joueur d&#39;encaisser \u00e0 tout moment.",
"bonus_rounds_p3": "Les tours de bonus peuvent avoir une limite de temps, apr\u00e8s laquelle ils deviennent nuls et inutilisables. La p\u00e9riode de validit\u00e9, le cas \u00e9ch\u00e9ant, est affich\u00e9e sous le tour de bonus.",
"bonus_rounds_p4": "Le tour bonus ne contribue pas au jackpot. Pendant le tour bonus, le jackpot ne peut pas \u00eatre remport\u00e9.",
"bonus_rounds_p5": "L&#39;inactivit\u00e9 d&#39;un joueur peut entra\u00eener l&#39;annulation d&#39;un tour bonus actif. Dans ce cas, le nombre de tours bonus diminue et le solde du joueur reste le m\u00eame.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Pari déjà actif',
};
langArr.he = {
'loading': 'טוען',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u05e1\u05d9\u05d1\u05d5\u05d1\u05d9 \u05d1\u05d5\u05e0\u05d5\u05e1",
"bonus_rounds_p1": "\u05e9\u05d7\u05e7\u05df \u05e2\u05e9\u05d5\u05d9 \u05dc\u05d6\u05db\u05d5\u05ea \u05d1\u05d0\u05e7\u05e8\u05d0\u05d9 \u05d1\u05e1\u05d1\u05d1\u05d9 \u05d1\u05d5\u05e0\u05d5\u05e1 (\u05db\u05dc\u05d5\u05de\u05e8 \u05d4\u05d9\u05de\u05d5\u05e8\u05d9\u05dd \u05d7\u05d9\u05e0\u05dd). \u05d2\u05e8\u05e4\u05d9\u05e7\u05d4 \u05e7\u05d5\u05e4\u05e6\u05ea \u05ea\u05d5\u05e4\u05d9\u05e2 \u05e2\u05dc \u05d4\u05de\u05e1\u05da \u05e9\u05ea\u05d5\u05d3\u05d9\u05e2 \u05dc\u05e9\u05d7\u05e7\u05df \u05e2\u05dc \u05e1\u05d9\u05d1\u05d5\u05d1\u05d9 \u05d1\u05d5\u05e0\u05d5\u05e1 \u05e9\u05d4\u05d5\u05e2\u05e0\u05e7\u05d5, \u05de\u05e1\u05e4\u05e8 \u05d4\u05d4\u05d9\u05de\u05d5\u05e8\u05d9\u05dd \u05d5\u05e1\u05db\u05d5\u05dd \u05dc\u05db\u05dc \u05d4\u05d9\u05de\u05d5\u05e8.",
"bonus_rounds_p2": "\u05dc\u05d0\u05d7\u05e8 \u05d4\u05e4\u05e8\u05e1 \u05d4\u05e9\u05d7\u05e7\u05df \u05d9\u05db\u05d5\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05de\u05ea\u05d9 \u05dc\u05d4\u05ea\u05d7\u05d9\u05dc \u05d0\u05ea \u05e1\u05d1\u05d1 \u05d4\u05d1\u05d5\u05e0\u05d5\u05e1. \u05dc\u05d0\u05d7\u05e8 \u05ea\u05d7\u05d9\u05dc\u05ea \u05e1\u05d1\u05d1\u05d9 \u05d4\u05d1\u05d5\u05e0\u05d5\u05e1, \u05d4\u05d4\u05d9\u05de\u05d5\u05e8 \u05d4\u05d0\u05d5\u05d8\u05d5\u05de\u05d8\u05d9 \u05e2\u05dd \u05d4\u05d4\u05d9\u05de\u05d5\u05e8 \u05e9\u05e6\u05d5\u05d9\u05df \u05d9\u05ea\u05d1\u05e6\u05e2, \u05d5\u05ea\u05dc\u05d5\u05d9 \u05d4\u05e9\u05d7\u05e7\u05df \u05dc\u05e4\u05d3\u05d5\u05ea \u05d0\u05d5\u05ea\u05d5 \u05d1\u05db\u05dc \u05e8\u05d2\u05e2 \u05e0\u05ea\u05d5\u05df.",
"bonus_rounds_p3": "\u05dc\u05e1\u05d9\u05d1\u05d5\u05d1\u05d9 \u05d1\u05d5\u05e0\u05d5\u05e1 \u05d9\u05db\u05d5\u05dc\u05d4 \u05dc\u05d4\u05d9\u05d5\u05ea \u05d4\u05d2\u05d1\u05dc\u05ea \u05d6\u05de\u05df, \u05e9\u05dc\u05d0\u05d7\u05e8\u05d9\u05d4 \u05d4\u05dd \u05d4\u05d5\u05e4\u05db\u05d9\u05dd \u05dc\u05d1\u05d8\u05dc\u05d9\u05dd \u05d5\u05d1\u05dc\u05ea\u05d9 \u05e0\u05d9\u05ea\u05e0\u05d9\u05dd \u05dc\u05e9\u05d9\u05de\u05d5\u05e9. \u05ea\u05e7\u05d5\u05e4\u05ea \u05d4\u05ea\u05d5\u05e7\u05e3, \u05d0\u05dd \u05d1\u05db\u05dc\u05dc, \u05de\u05d5\u05e6\u05d2\u05ea \u05de\u05ea\u05d7\u05ea \u05dc\u05e1\u05d1\u05d1 \u05d4\u05d1\u05d5\u05e0\u05d5\u05e1.",
"bonus_rounds_p4": "\u05e1\u05d1\u05d1 \u05d1\u05d5\u05e0\u05d5\u05e1 \u05d0\u05d9\u05e0\u05d5 \u05ea\u05d5\u05e8\u05dd \u05dc\u05e7\u05e8\u05df \u05e7\u05d5\u05e4\u05d4. \u05d1\u05de\u05d4\u05dc\u05da \u05e1\u05d1\u05d1 \u05d4\u05d1\u05d5\u05e0\u05d5\u05e1 \u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d6\u05db\u05d5\u05ea \u05d1\u05e7\u05d5\u05e4\u05d4.",
"bonus_rounds_p5": "\u05d1\u05d8\u05dc\u05ea \u05e9\u05d7\u05e7\u05df \u05e2\u05dc\u05d5\u05dc \u05dc\u05d2\u05e8\u05d5\u05dd \u05dc\u05d1\u05d9\u05d8\u05d5\u05dc \u05e1\u05d1\u05d1 \u05d1\u05d5\u05e0\u05d5\u05e1 \u05e4\u05e2\u05d9\u05dc. \u05d1\u05de\u05e7\u05e8\u05d4 \u05d6\u05d4, \u05de\u05e1\u05e4\u05e8 \u05e1\u05d9\u05d1\u05d5\u05d1\u05d9 \u05d4\u05d1\u05d5\u05e0\u05d5\u05e1 \u05de\u05e6\u05d8\u05de\u05e6\u05dd \u05d5\u05d4\u05d9\u05ea\u05e8\u05d4 \u05e9\u05dc \u05d4\u05e9\u05d7\u05e7\u05df \u05e0\u05e9\u05d0\u05e8\u05ea \u05d6\u05d4\u05d4.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'ההימור כבר פעיל',
};
langArr.hr = {
'loading': 'Učitavanje',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Bonus runde",
"bonus_rounds_p1": "Igra\u010d mo\u017ee biti nasumi\u010dno nagra\u0111en bonus rundama (tj. besplatnim okladama). Na zaslonu \u0107e se pojaviti sko\u010dna grafika koja obavje\u0161tava igra\u010da o dodijeljenim bonus rundama, broju oklada i iznosu po okladi.",
"bonus_rounds_p2": "Nakon \u0161to su nagra\u0111eni, igra\u010d mo\u017ee odabrati kada \u0107e zapo\u010deti bonus krug. Nakon pokretanja bonus rundi postavlja se automatska oklada s navedenim ulogom, a na igra\u010du je da u bilo kojem trenutku izvr\u0161i isplatu.",
"bonus_rounds_p3": "Bonus runde mogu imati vremensko ograni\u010denje, nakon \u010dega postaju ni\u0161tavne i neupotrebljive. Razdoblje valjanosti, ako postoji, prikazano je ispod bonus runde.",
"bonus_rounds_p4": "Bonus runda ne doprinosi jackpot fondu. Tijekom bonus runde jackpot se ne mo\u017ee osvojiti.",
"bonus_rounds_p5": "Neaktivnost igra\u010da mo\u017ee rezultirati otkazivanjem aktivne bonus runde. U tom slu\u010daju, broj bonus rundi se smanjuje, a saldo igra\u010da ostaje isti.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Oklada je već aktivna',
};
langArr.hu = {
'loading': 'Betöltés',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "B\u00f3nusz k\u00f6r\u00f6k",
"bonus_rounds_p1": "Egy j\u00e1t\u00e9kos v\u00e9letlenszer\u0171en kaphat b\u00f3nuszk\u00f6r\u00f6ket (azaz ingyenes fogad\u00e1sokat). Egy felugr\u00f3 \u00e1bra jelenik meg a k\u00e9perny\u0151n, amely \u00e9rtes\u00edti a j\u00e1t\u00e9kost a b\u00f3nusz k\u00f6r\u00f6kr\u0151l, a fogad\u00e1sok sz\u00e1m\u00e1r\u00f3l \u00e9s a fogad\u00e1sonk\u00e9nti \u00f6sszegr\u0151l.",
"bonus_rounds_p2": "Miut\u00e1n megkapt\u00e1k a d\u00edjat, a j\u00e1t\u00e9kos v\u00e1laszthat, hogy mikor kezdje meg a b\u00f3nuszk\u00f6rt. A b\u00f3nuszk\u00f6r elind\u00edt\u00e1sa ut\u00e1n a megadott t\u00e9ttel automatikus t\u00e9t ker\u00fcl megt\u00e9telre, \u00e9s a j\u00e1t\u00e9koson m\u00falik, hogy b\u00e1rmikor kifizetheti-e.",
"bonus_rounds_p3": "A b\u00f3nuszk\u00f6r\u00f6knek id\u0151korl\u00e1tja lehet, amely ut\u00e1n \u00e9rv\u00e9nytelenn\u00e9 \u00e9s haszn\u00e1lhatatlann\u00e1 v\u00e1lnak. Az \u00e9rv\u00e9nyess\u00e9gi id\u0151, ha van, a b\u00f3nuszk\u00f6r alatt l\u00e1that\u00f3.",
"bonus_rounds_p4": "A b\u00f3nuszk\u00f6r nem j\u00e1rul hozz\u00e1 a jackpot alaphoz. A b\u00f3nuszk\u00f6r alatt a jackpot nem nyerhet\u0151.",
"bonus_rounds_p5": "A j\u00e1t\u00e9kos t\u00e9tlens\u00e9ge egy akt\u00edv b\u00f3nuszk\u00f6r t\u00f6rl\u00e9s\u00e9t eredm\u00e9nyezheti. Ebben az esetben a b\u00f3nusz k\u00f6r\u00f6k sz\u00e1ma cs\u00f6kken, \u00e9s a j\u00e1t\u00e9kos egyenlege v\u00e1ltozatlan marad.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'A fogadás már aktív',
};
langArr.it = {
'loading': 'Caricamento',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Round bonus",
"bonus_rounds_p1": "Un giocatore pu\u00f2 ricevere casualmente dei round bonus (ad esempio scommesse gratuite). Un pop-up grafico apparir\u00e0 sullo schermo per notificare al giocatore i round bonus assegnati, il numero di scommesse e l&#39;importo per scommessa.",
"bonus_rounds_p2": "Una volta che vengono assegnati, il giocatore pu\u00f2 scegliere quando iniziare il round bonus. Una volta iniziati i round bonus, viene piazzata una scommessa automatica con la puntata specificata e spetta al giocatore incassare in qualsiasi momento.",
"bonus_rounds_p3": "I round bonus potrebbero avere un limite di tempo, dopo il quale diventano nulli e inutilizzabili. Il periodo di validit\u00e0, se presente, \u00e8 visualizzato sotto il round bonus.",
"bonus_rounds_p4": "Il round bonus non contribuisce al fondo del jackpot. Durante il round bonus il jackpot non pu\u00f2 essere vinto.",
"bonus_rounds_p5": "L&#39;inattivit\u00e0 del giocatore pu\u00f2 comportare l&#39;annullamento di un round bonus attivo. In questo caso, il numero di round bonus diminuisce e il saldo del giocatore rimane invariato.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Scommessa già attiva',
};
langArr.mk = {
'loading': 'Се вчитува',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u0411\u043e\u043d\u0443\u0441 \u0440\u0443\u043d\u0434\u0438",
"bonus_rounds_p1": "\u0418\u0433\u0440\u0430\u0447\u043e\u0442 \u043c\u043e\u0436\u0435 \u043f\u043e \u0441\u043b\u0443\u0447\u0430\u0435\u043d \u0438\u0437\u0431\u043e\u0440 \u0434\u0430 \u0431\u0438\u0434\u0435 \u043d\u0430\u0433\u0440\u0430\u0434\u0435\u043d \u0441\u043e \u0431\u043e\u043d\u0443\u0441 \u043a\u0440\u0443\u0433\u043e\u0432\u0438 (\u0442.\u0435. \u0431\u0435\u0441\u043f\u043b\u0430\u0442\u043d\u0438 \u043e\u0431\u043b\u043e\u0437\u0438). \u041d\u0430 \u0435\u043a\u0440\u0430\u043d\u043e\u0442 \u045c\u0435 \u0441\u0435 \u043f\u043e\u0458\u0430\u0432\u0438 \u0441\u043a\u043e\u043a\u0430\u0447\u043a\u0430 \u0433\u0440\u0430\u0444\u0438\u043a\u0430 \u043a\u043e\u0458\u0430 \u0433\u043e \u0438\u0437\u0432\u0435\u0441\u0442\u0443\u0432\u0430 \u0438\u0433\u0440\u0430\u0447\u043e\u0442 \u0437\u0430 \u0434\u043e\u0434\u0435\u043b\u0435\u043d\u0438\u0442\u0435 \u0431\u043e\u043d\u0443\u0441 \u043a\u0440\u0443\u0433\u043e\u0432\u0438, \u0431\u0440\u043e\u0458\u043e\u0442 \u043d\u0430 \u043e\u0431\u043b\u043e\u0437\u0438 \u0438 \u0438\u0437\u043d\u043e\u0441\u043e\u0442 \u043f\u043e \u043e\u0431\u043b\u043e\u0433.",
"bonus_rounds_p2": "\u041e\u0442\u043a\u0430\u043a\u043e \u045c\u0435 \u0438\u043c \u0441\u0435 \u0434\u043e\u0434\u0435\u043b\u0438, \u0438\u0433\u0440\u0430\u0447\u043e\u0442 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435 \u043a\u043e\u0433\u0430 \u0434\u0430 \u0458\u0430 \u0437\u0430\u043f\u043e\u0447\u043d\u0435 \u0431\u043e\u043d\u0443\u0441\u043d\u0430\u0442\u0430 \u0440\u0443\u043d\u0434\u0430. \u0428\u0442\u043e\u043c \u045c\u0435 \u0437\u0430\u043f\u043e\u0447\u043d\u0430\u0442 \u0431\u043e\u043d\u0443\u0441\u043d\u0438\u0442\u0435 \u043a\u0440\u0443\u0433\u043e\u0432\u0438, \u0441\u0435 \u0441\u0442\u0430\u0432\u0430 \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0441\u043a\u0438 \u043e\u0431\u043b\u043e\u0433 \u0441\u043e \u043d\u0430\u0432\u0435\u0434\u0435\u043d\u0438\u043e\u0442 \u0432\u043b\u043e\u0433 \u0438 \u043d\u0430 \u0438\u0433\u0440\u0430\u0447\u043e\u0442 \u0435 \u0434\u0430 \u0443\u043f\u043b\u0430\u0442\u0438 \u0432\u043e \u0441\u0435\u043a\u043e\u0458 \u0434\u0430\u0434\u0435\u043d \u043c\u043e\u043c\u0435\u043d\u0442.",
"bonus_rounds_p3": "\u0411\u043e\u043d\u0443\u0441\u043d\u0438\u0442\u0435 \u043a\u0440\u0443\u0433\u043e\u0432\u0438 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0438\u043c\u0430\u0430\u0442 \u0432\u0440\u0435\u043c\u0435\u043d\u0441\u043a\u043e \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0443\u0432\u0430\u045a\u0435, \u043f\u043e \u0448\u0442\u043e \u0441\u0442\u0430\u043d\u0443\u0432\u0430\u0430\u0442 \u043d\u0435\u0432\u0430\u0436\u0435\u0447\u043a\u0438 \u0438 \u043d\u0435\u0443\u043f\u043e\u0442\u0440\u0435\u0431\u043b\u0438\u0432\u0438. \u041f\u0435\u0440\u0438\u043e\u0434\u043e\u0442 \u043d\u0430 \u0432\u0430\u0436\u043d\u043e\u0441\u0442, \u0434\u043e\u043a\u043e\u043b\u043a\u0443 \u0433\u043e \u0438\u043c\u0430, \u0441\u0435 \u043f\u0440\u0438\u043a\u0430\u0436\u0443\u0432\u0430 \u043f\u043e\u0434 \u043a\u0440\u0443\u0433\u043e\u0442 \u043d\u0430 \u0431\u043e\u043d\u0443\u0441.",
"bonus_rounds_p4": "\u0411\u043e\u043d\u0443\u0441 \u043a\u0440\u0443\u0433 \u043d\u0435 \u043f\u0440\u0438\u0434\u043e\u043d\u0435\u0441\u0443\u0432\u0430 \u0437\u0430 \u045f\u0435\u043a\u043f\u043e\u0442 \u0444\u043e\u043d\u0434. \u0417\u0430 \u0432\u0440\u0435\u043c\u0435 \u043d\u0430 \u0431\u043e\u043d\u0443\u0441 \u043a\u0440\u0443\u0433 \u045f\u0435\u043a\u043f\u043e\u0442 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0441\u0435 \u043e\u0441\u0432\u043e\u0438.",
"bonus_rounds_p5": "\u041d\u0435\u0440\u0430\u0431\u043e\u0442\u0435\u045a\u0435\u0442\u043e \u043d\u0430 \u0438\u0433\u0440\u0430\u0447\u043e\u0442 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0440\u0435\u0437\u0443\u043b\u0442\u0438\u0440\u0430 \u0441\u043e \u043e\u0442\u043a\u0430\u0436\u0443\u0432\u0430\u045a\u0435 \u043d\u0430 \u0430\u043a\u0442\u0438\u0432\u0435\u043d \u0431\u043e\u043d\u0443\u0441 \u043a\u0440\u0443\u0433. \u0412\u043e \u043e\u0432\u043e\u0458 \u0441\u043b\u0443\u0447\u0430\u0458, \u0431\u0440\u043e\u0458\u043e\u0442 \u043d\u0430 \u0431\u043e\u043d\u0443\u0441\u043d\u0438\u0442\u0435 \u043a\u0440\u0443\u0433\u043e\u0432\u0438 \u0441\u0435 \u043d\u0430\u043c\u0430\u043b\u0443\u0432\u0430 \u0438 \u0431\u0438\u043b\u0430\u043d\u0441\u043e\u0442 \u043d\u0430 \u0438\u0433\u0440\u0430\u0447\u043e\u0442 \u043e\u0441\u0442\u0430\u043d\u0443\u0432\u0430 \u0438\u0441\u0442.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Облогот е веќе активен',
};
langArr.pl = {
'loading': 'Ładowanie',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Rundy bonusowe",
"bonus_rounds_p1": "Gracz mo\u017ce zosta\u0107 losowo nagrodzony rundami bonusowymi (tj. darmowymi zak\u0142adami). Na ekranie pojawi si\u0119 grafika pop-up informuj\u0105ca gracza o przyznanych rundach bonusowych, liczbie zak\u0142ad\u00f3w i kwocie na zak\u0142ad.",
"bonus_rounds_p2": "Po ich przyznaniu gracz mo\u017ce wybra\u0107, kiedy rozpocz\u0105\u0107 rund\u0119 bonusow\u0105. Po rozpocz\u0119ciu rundy bonusowej, automatyczny zak\u0142ad z okre\u015blon\u0105 stawk\u0105 jest umieszczany, a gracz musi wyp\u0142aci\u0107 pieni\u0105dze w dowolnym momencie.",
"bonus_rounds_p3": "Rundy bonusowe mog\u0105 mie\u0107 limit czasowy, po kt\u00f3rym staj\u0105 si\u0119 niewa\u017cne i bezu\u017cyteczne. Okres wa\u017cno\u015bci, je\u015bli istnieje, jest wy\u015bwietlany pod rund\u0105 bonusow\u0105.",
"bonus_rounds_p4": "Runda bonusowa nie przyczynia si\u0119 do puli jackpota. Podczas rundy bonusowej jackpota nie mo\u017cna wygra\u0107.",
"bonus_rounds_p5": "Bezczynno\u015b\u0107 gracza mo\u017ce skutkowa\u0107 anulowaniem aktywnej rundy bonusowej. W takim przypadku liczba rund bonusowych jest zmniejszana, a saldo gracza pozostaje takie samo.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Zakład już aktywny',
};
langArr.pt = {
'loading': 'Carregando',
'hotkeys': 'Teclas de atalho',
'hotkeys_enabled': 'Teclas de atalho ativadas',
'enable_hotkeys_checkbox': 'Habilitar teclas de atalho abaixo marcando esta caixa.',
'make_bet': 'Fazer uma aposta',
'double_bet': 'Dobrar o valor da aposta',
'halve_bet': 'Reduzir o valor da aposta pela metade',
'min_bet': 'Valor mínimo da aposta',
'cashout_key': 'Saque',
'walk_next_line': 'Andar para a próxima linha',
'max': 'Máximo',
'easy': 'Fácil',
'medium': 'Médio',
'hard': 'Difícil',
'daredevil': 'Audacioso',
'max_profit': 'Lucro Máximo',
'manual': 'Manual',
'auto': 'Automático',
'bet_amount': 'Valor da aposta',
'difficulty': 'Dificuldade',
'payout_on_win': 'Pagamento em caso de vitória',
'number_of_bets': 'Número de apostas',
'on_win': 'Em caso de vitória',
'on_loss': 'Em caso de perda',
'stop_on_profit': 'Parar ao atingir lucro',
'stop_on_loss': 'Parar ao atingir perda',
'reset': 'Reiniciar',
'increase_by': 'Aumentar por',
'cashout': 'SAQUE',
'start_game': 'APOSTA',
'start_auto_game': 'INICIAR',
'difficulity_info': 'Informações sobre a dificuldade',
'chance_collision': 'Chance de colisão por jogo',
'evry_25_lane': 'A cada 25 linhas',
'insufficiend_funds': 'Fundos insuficientes',
'cashout_inactivity': 'Saque automático após inatividade',
'time_running_out': 'O tempo está se esgotando! Jogue agora ou seu bilhete será retirado automaticamente em 30 segundos',
'there_was_error': 'OCORREU UM ERRO, TENTE NOVAMENTE OU RECARREGUE',
'detailed_game_rules': 'Regras detalhadas do jogo',
'welcome': 'Bem-vindo ao Soccer Rush - um jogo novo e emocionante!',
'general_rules': 'REGRAS GERAIS',
'general_rules_p1': '• O multiplicador começa em 1 e vai até',
'general_rules_p2': '• Os ganhos são calculados multiplicando o valor da aposta pelo multiplicador no momento do evento de saque',
'general_rules_p3': '• Se o pagamento máximo for alcançado, a aposta será automaticamente sacada no valor máximo de ganho',
'progressive_jackpot': 'JACKPOT PROGRESSIVO',
'progressive_jackpot_p1': '1% de cada aposta é destinado a um fundo de jackpot progressivo.',
'progressive_jackpot_p2': 'O jackpot é concedido usando uma mecânica "Mistério" ou "must-hit-by". O valor de cada jackpot "must-hit-by" é determinado imediatamente após o jackpot anterior ser ganho por um gerador de números aleatórios e armazenado como um valor criptografado no banco de dados remoto. O valor é criptografado, e assim, oculto e desconhecido.',
'progressive_jackpot_p3': 'Uma vez que o valor "Must-Hit-By" seja alcançado, o primeiro jogador a fazer uma aposta depois disso terá a chance de ganhar o jackpot. Um símbolo de jackpot aparecerá em uma linha aleatória, e o jackpot será ganho se o jogador alcançar a linha com o símbolo. Se o jogador não alcançar a linha com o símbolo do jackpot devido ao saque ou colisão, o próximo jogador a fazer uma aposta verá o símbolo do jackpot aparecer e terá a chance de ganhar. Esse processo se repete até que o jackpot seja ganho.',
'showdown': 'CONFRONTO',
'showdown_p1': 'Após o jogador sacar, um quadrado vermelho será exibido no local onde a colisão teria ocorrido se o jogador tivesse continuado pulando as linhas.',
'return_to_player': 'RETORNO AO JOGADOR',
'return_to_player_p1': 'O retorno ao jogador (RTP), incluindo retornos do jogo e jackpots, é definido em 98%, dos quais 97% vêm diretamente do RTP do jogo, com 1% adicional vindo do jackpot.',
'return_to_player_p2': 'As porcentagens de RTP são monitoradas e verificadas continuamente por terceiros independentes.',
'disconnection_policy': 'POLÍTICA DE DESCONEXÃO',
'disconnection_policy_p1': 'Se a conexão for perdida após uma aposta ser feita, a aposta não será cancelada. Se a inatividade do jogador atingir',
'disconnection_policy_p2': 'sua aposta será automaticamente sacada e os créditos serão aplicados à sua conta.',
'network_latency': 'LATÊNCIA DE REDE',
'network_latency_p1': 'A latência de rede é uma parte inevitável da conectividade com a internet. Consideramos todas as apostas e saques válidos apenas no momento em que chegam ao nosso servidor, independentemente da duração da latência de rede.',
'sound': 'SOM',
'sound_p1': 'O Soccer Rush foi projetado com belas músicas de fundo e efeitos sonoros em mente. Caso deseje desativar a música e/ou efeitos sonoros, você pode fazer isso através dos ícones para efeitos (1) e som (2) localizados no canto inferior direito da tela.',
'error_handling': 'MANUSEIO DE ERROS',
'error_handling_p1': 'In caso de erro, nossa equipe de suporte tentará resolver o problema o mais rápido possível e retomar o jogo normal. Em caso de problema com o jogo em si, todas as apostas serão canceladas e o valor apostado será devolvido aos jogadores.',
'max_payout': 'O pagamento máximo é',
'max_bet_is': 'A aposta máxima é',
'min_bet_is': 'A aposta mínima é',
'per_bet': 'por aposta.',
'malfunction_voids': 'Falhas anulam todos os pagamentos e jogadas.',
'attention_jackpot': 'Atenção, Jackpot na linha',
'lane': 'linha',
'congratulations': 'parabéns',
'you_won': 'você ganhou',
'balance': 'saldo',
'time': 'tempo',
'jackpot': 'jackpot',
'escaped': 'ESCAPOU',
'max_win': 'GANHO MÁXIMO',
'check_internet': 'Por favor, verifique sua conexão com a internet',
'websocket_disconected': 'Websocket desconectado',
'websocket_connection_error': 'Erro de conexão Websocket!',
'session_expired': 'Sessão expirada, por favor recarregue',
'remaining_bets': 'Apostas restantes',
'stop_autoplay': 'PARAR',
'autoplay_and_autocashout': 'AUTOPLAY E AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'O jogador pode ativar a opção de autoplay onde a aposta é colocada automaticamente para cada nova rodada, usando o valor da aposta anterior.',
'autoplay_and_autocashout_p2': 'Um multiplicador de saque automático pode ser selecionado na tela do jogo e, quando definido, o jogo fará o saque automaticamente se o jogador não for atingido antes de atingir o multiplicador selecionado.',
'autoplay_button_label': 'Para começar, escolha uma linha.',
'min_bet_amount_is': 'O valor mínimo da aposta é',
'bonus_rounds': 'rodadas bônus',
'bonus_rounds_p1': 'Um jogador pode ser premiado aleatoriamente com rodadas bônus (ou seja, apostas grátis). Um gráfico pop-up aparecerá na tela notificando o jogador sobre as rodadas bônus premiadas, o número de apostas e o valor por aposta.',
'bonus_rounds_p2': 'Uma vez premiado, o jogador pode escolher quando iniciar a rodada bônus. Uma vez iniciada a rodada bônus, uma aposta automática com o valor especificado é feita, e o jogador pode sacar a qualquer momento.',
'bonus_rounds_p3': 'As rodadas bônus podem ter um limite de tempo, após o qual se tornam inválidas e não utilizáveis. O período de validade, se houver, é exibido abaixo da rodada bônus.',
'bonus_rounds_p4': 'A inatividade do jogador pode resultar no cancelamento de uma rodada bônus ativa. Nesse caso, o número de rodadas bônus diminui e o saldo do jogador permanece o mesmo.',
'valid_until': 'válido até',
'bonus': 'bônus',
'rounds': 'rodadas',
'win_eur': 'ganhar (EUR)',
'complete_your_bet': 'Complete sua aposta primeiro',
'complete_auto_play': 'Complete seu autoplay primeiro',
'yes': 'sim',
'no': 'não',
'play_bonus_rounds': 'Jogar rodadas bônus?',
'bet_history': 'Histórico de apostas',
'jackpot_history': 'Histórico de jackpot',
'ticket_id': 'ID do bilhete',
'created': 'Hora de criação',
'currency': 'moeda',
'bet': 'aposta',
'bet_eur': 'aposta (EUR)',
'lanes': 'linhas',
'cars': 'carros',
'multiplier': 'multiplicador',
'win': 'ganhar',
'no_betting_history': 'Nenhum histórico de apostas encontrado. Faça uma aposta, e ela aparecerá aqui.',
'no_jackpot_history': 'Nenhum histórico de jackpot disponível.',
'date': 'data',
'user': 'usuário',
'bet_already_active': 'Aposta já ativa',
};
langArr.br = {
'loading': 'A carregar',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Troio\u00f9 bonus",
"bonus_rounds_p1": "Ur c&#39;hoarier a c&#39;hell beza\u00f1 roet troio\u00f9 bonus dre zegouezh (da l\u00e2ret eo ar c&#39;hoario\u00f9 digoust). Ur grafik a zeuio war wel war ar skramm o kemenn d&#39;ar c&#39;hoarier diwar-benn ar c&#39;hrogado\u00f9 bonus roet, an niver a c&#39;hoario\u00f9 hag ar sammad dre c&#39;hoari.",
"bonus_rounds_p2": "Ur wech roet ar priz e c&#39;hall ar c&#39;hoarier dibab pegoulz kregi\u00f1 gant ar c&#39;hrogad bonus. Ur wech kroget ar c&#39;hrogado\u00f9 bonus e vez lakaet ur c&#39;hoari emgefre gant ar c&#39;hoari spisaet, ha d&#39;ar c&#39;hoarier eo da gas arc&#39;hant d&#39;ur mare bennak.",
"bonus_rounds_p3": "Ar c&#39;hrogado\u00f9 bonus a c&#39;hellfe kaout ur prantad amzer, ha goude-se e teuont da veza\u00f1 null ha didalvoudek. Diskouezet e vez ar prantad talvoudegezh, ma vez, dindan ar c&#39;hrogad bonus.",
"bonus_rounds_p4": "Ar c&#39;hrogad bonus ne gemer ket perzh en ur fo\u00f1s jackpot. E-pad ar c&#39;hrogad bonus ne c&#39;haller ket gounit ar jackpot.",
"bonus_rounds_p5": "Pa vez didalvoud ar c&#39;hoarier e c&#39;hallfe beza\u00f1 nullet ur c&#39;hrogad bonus oberiant. En degouezh-ma\u00f1 e vez digresket an niver a droio\u00f9 bonus ha chom a ra he\u00f1vel saldo ar c&#39;hoarier.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Aposta já ativa',
};
langArr.ro = {
'loading': 'Se încarcă',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Runde bonus",
"bonus_rounds_p1": "Un juc\u0103tor poate fi premiat aleatoriu cu runde bonus (adic\u0103 pariuri gratuite). Un grafic pop-up va ap\u0103rea pe ecran care anun\u021b\u0103 juc\u0103torul despre rundele bonus acordate, num\u0103rul de pariuri \u0219i suma per pariu.",
"bonus_rounds_p2": "Odat\u0103 ce sunt premia\u021bi, juc\u0103torul poate alege c\u00e2nd s\u0103 \u00eenceap\u0103 runda bonus. Odat\u0103 ce rundele bonus sunt \u00eencepute, este plasat un pariu automat cu miza specificat\u0103 \u0219i este la latitudinea juc\u0103torului s\u0103 \u00eencaseze \u00een orice moment.",
"bonus_rounds_p3": "Rundele bonus ar putea avea o limit\u0103 de timp, dup\u0103 care devin nule \u0219i inutilizabile. Perioada de valabilitate, dac\u0103 exist\u0103, este afi\u0219at\u0103 sub runda de bonus.",
"bonus_rounds_p4": "Runda bonus nu contribuie la un fond de jackpot. \u00cen timpul rundei bonus, jackpot-ul nu poate fi c\u00e2\u0219tigat.",
"bonus_rounds_p5": "Inactivitatea juc\u0103torului poate duce la anularea unei runde bonus active. \u00cen acest caz, num\u0103rul rundelor bonus scade \u0219i soldul juc\u0103torului r\u0103m\u00e2ne acela\u0219i.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Pariul este deja activ',
};
langArr.ru = {
'loading': 'Загрузка',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u0411\u043e\u043d\u0443\u0441\u043d\u044b\u0435 \u0440\u0430\u0443\u043d\u0434\u044b",
"bonus_rounds_p1": "\u0418\u0433\u0440\u043e\u043a \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u0441\u043b\u0443\u0447\u0430\u0439\u043d\u044b\u043c \u043e\u0431\u0440\u0430\u0437\u043e\u043c \u043d\u0430\u0433\u0440\u0430\u0436\u0434\u0435\u043d \u0431\u043e\u043d\u0443\u0441\u043d\u044b\u043c\u0438 \u0440\u0430\u0443\u043d\u0434\u0430\u043c\u0438 (\u0442.\u0435. \u0431\u0435\u0441\u043f\u043b\u0430\u0442\u043d\u044b\u043c\u0438 \u0441\u0442\u0430\u0432\u043a\u0430\u043c\u0438). \u041d\u0430 \u044d\u043a\u0440\u0430\u043d\u0435 \u043f\u043e\u044f\u0432\u0438\u0442\u0441\u044f \u0432\u0441\u043f\u043b\u044b\u0432\u0430\u044e\u0449\u0430\u044f \u0433\u0440\u0430\u0444\u0438\u043a\u0430, \u0443\u0432\u0435\u0434\u043e\u043c\u043b\u044f\u044e\u0449\u0430\u044f \u0438\u0433\u0440\u043e\u043a\u0430 \u043e \u043f\u0440\u0438\u0441\u0443\u0436\u0434\u0435\u043d\u043d\u044b\u0445 \u0431\u043e\u043d\u0443\u0441\u043d\u044b\u0445 \u0440\u0430\u0443\u043d\u0434\u0430\u0445, \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u0435 \u0441\u0442\u0430\u0432\u043e\u043a \u0438 \u0441\u0443\u043c\u043c\u0435 \u043d\u0430 \u0441\u0442\u0430\u0432\u043a\u0443.",
"bonus_rounds_p2": "\u041f\u043e\u0441\u043b\u0435 \u0442\u043e\u0433\u043e, \u043a\u0430\u043a \u043e\u043d\u0438 \u0431\u0443\u0434\u0443\u0442 \u043f\u0440\u0438\u0441\u0443\u0436\u0434\u0435\u043d\u044b, \u0438\u0433\u0440\u043e\u043a \u043c\u043e\u0436\u0435\u0442 \u0432\u044b\u0431\u0440\u0430\u0442\u044c, \u043a\u043e\u0433\u0434\u0430 \u043d\u0430\u0447\u0430\u0442\u044c \u0431\u043e\u043d\u0443\u0441\u043d\u044b\u0439 \u0440\u0430\u0443\u043d\u0434. \u041f\u043e\u0441\u043b\u0435 \u043d\u0430\u0447\u0430\u043b\u0430 \u0431\u043e\u043d\u0443\u0441\u043d\u044b\u0445 \u0440\u0430\u0443\u043d\u0434\u043e\u0432 \u0434\u0435\u043b\u0430\u0435\u0442\u0441\u044f \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0441\u0442\u0430\u0432\u043a\u0430 \u0441 \u0443\u043a\u0430\u0437\u0430\u043d\u043d\u043e\u0439 \u0441\u0443\u043c\u043c\u043e\u0439, \u0438 \u0438\u0433\u0440\u043e\u043a \u043c\u043e\u0436\u0435\u0442 \u0432\u044b\u0432\u0435\u0441\u0442\u0438 \u0434\u0435\u043d\u044c\u0433\u0438 \u0432 \u043b\u044e\u0431\u043e\u0439 \u043c\u043e\u043c\u0435\u043d\u0442.",
"bonus_rounds_p3": "\u0411\u043e\u043d\u0443\u0441\u043d\u044b\u0435 \u0440\u0430\u0443\u043d\u0434\u044b \u043c\u043e\u0433\u0443\u0442 \u0438\u043c\u0435\u0442\u044c \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u0438\u0435 \u043f\u043e \u0432\u0440\u0435\u043c\u0435\u043d\u0438, \u043f\u043e\u0441\u043b\u0435 \u043a\u043e\u0442\u043e\u0440\u043e\u0433\u043e \u043e\u043d\u0438 \u0441\u0442\u0430\u043d\u043e\u0432\u044f\u0442\u0441\u044f \u043d\u0435\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u044b\u043c\u0438 \u0438 \u043d\u0435\u043f\u0440\u0438\u0433\u043e\u0434\u043d\u044b\u043c\u0438 \u0434\u043b\u044f \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043d\u0438\u044f. \u0421\u0440\u043e\u043a \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044f, \u0435\u0441\u043b\u0438 \u0442\u0430\u043a\u043e\u0432\u043e\u0439 \u0438\u043c\u0435\u0435\u0442\u0441\u044f, \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044f \u043f\u043e\u0434 \u0431\u043e\u043d\u0443\u0441\u043d\u044b\u043c \u0440\u0430\u0443\u043d\u0434\u043e\u043c.",
"bonus_rounds_p4": "\u0411\u043e\u043d\u0443\u0441\u043d\u044b\u0439 \u0440\u0430\u0443\u043d\u0434 \u043d\u0435 \u0432\u043d\u043e\u0441\u0438\u0442 \u0432\u043a\u043b\u0430\u0434 \u0432 \u0444\u043e\u043d\u0434 \u0434\u0436\u0435\u043a\u043f\u043e\u0442\u0430. \u0412\u043e \u0432\u0440\u0435\u043c\u044f \u0431\u043e\u043d\u0443\u0441\u043d\u043e\u0433\u043e \u0440\u0430\u0443\u043d\u0434\u0430 \u0434\u0436\u0435\u043a\u043f\u043e\u0442 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u0432\u044b\u0438\u0433\u0440\u0430\u043d.",
"bonus_rounds_p5": "\u0411\u0435\u0437\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0438\u0433\u0440\u043e\u043a\u0430 \u043c\u043e\u0436\u0435\u0442 \u043f\u0440\u0438\u0432\u0435\u0441\u0442\u0438 \u043a \u043e\u0442\u043c\u0435\u043d\u0435 \u0430\u043a\u0442\u0438\u0432\u043d\u043e\u0433\u043e \u0431\u043e\u043d\u0443\u0441\u043d\u043e\u0433\u043e \u0440\u0430\u0443\u043d\u0434\u0430. \u0412 \u044d\u0442\u043e\u043c \u0441\u043b\u0443\u0447\u0430\u0435 \u043a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0431\u043e\u043d\u0443\u0441\u043d\u044b\u0445 \u0440\u0430\u0443\u043d\u0434\u043e\u0432 \u0443\u043c\u0435\u043d\u044c\u0448\u0430\u0435\u0442\u0441\u044f, \u0430 \u0431\u0430\u043b\u0430\u043d\u0441 \u0438\u0433\u0440\u043e\u043a\u0430 \u043e\u0441\u0442\u0430\u0435\u0442\u0441\u044f \u043f\u0440\u0435\u0436\u043d\u0438\u043c.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Ставка уже активна',
};
langArr.sq = {
'loading': 'Po ngarkohet',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Raundet e bonusit",
"bonus_rounds_p1": "Nj\u00eb lojtar mund t\u00eb shp\u00ebrblehet rast\u00ebsisht me raunde bonus (dmth. baste falas). Nj\u00eb grafik\u00eb k\u00ebrcyese do t\u00eb shfaqet n\u00eb ekran duke njoftuar lojtarin p\u00ebr raundet e bonusit t\u00eb dh\u00ebn\u00eb, numrin e basteve dhe shum\u00ebn p\u00ebr bast.",
"bonus_rounds_p2": "Pasi t\u00eb jepen, lojtari mund t\u00eb zgjedh\u00eb se kur do t\u00eb filloj\u00eb raundin e bonusit. Pasi t\u00eb fillojn\u00eb raundet e bonusit, vendoset nj\u00eb bast automatik me aksionin e specifikuar dhe i takon lojtarit t\u00eb b\u00ebj\u00eb para n\u00eb \u00e7do moment.",
"bonus_rounds_p3": "Raundet e bonusit mund t\u00eb ken\u00eb nj\u00eb afat kohor, pas s\u00eb cil\u00ebs ato b\u00ebhen t\u00eb pavlefshme dhe t\u00eb pap\u00ebrdorshme. Periudha e vlefshm\u00ebris\u00eb, n\u00ebse ka, shfaqet n\u00ebn raundin e bonusit.",
"bonus_rounds_p4": "Raundi i bonusit nuk kontribuon n\u00eb nj\u00eb fond \u00e7mim i par\u00eb. Gjat\u00eb raundit t\u00eb bonusit, nuk mund t\u00eb fitohet xhekpoti.",
"bonus_rounds_p5": "P\u00ebrtacia e lojtarit mund t\u00eb rezultoj\u00eb n\u00eb anulimin e nj\u00eb raundi aktiv bonusi. N\u00eb k\u00ebt\u00eb rast, numri i raundeve t\u00eb bonusit zvog\u00eblohet dhe bilanci i lojtarit mbetet i nj\u00ebjt\u00eb.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Basti është tashmë aktiv',
};
langArr.sv = {
'loading': 'Laddar',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Bonusrundor",
"bonus_rounds_p1": "En spelare kan slumpm\u00e4ssigt tilldelas bonusrundor (dvs gratisspel). En popup-grafik kommer att visas p\u00e5 sk\u00e4rmen som meddelar spelaren om tilldelade bonusrundor, antal insatser och belopp per insats.",
"bonus_rounds_p2": "N\u00e4r de har tilldelats kan spelaren v\u00e4lja n\u00e4r bonusrundan ska b\u00f6rja. N\u00e4r bonusrundorna v\u00e4l har startat placeras automatisk insats med angiven insats, och det \u00e4r upp till spelaren att ta ut pengar n\u00e4r som helst.",
"bonus_rounds_p3": "Bonusrundor kan ha en tidsbegr\u00e4nsning, varefter de blir ogiltiga och oanv\u00e4ndbara. Giltighetsperioden, om n\u00e5gon, visas under bonusrundan.",
"bonus_rounds_p4": "Bonusrunda bidrar inte till en jackpottfond. Under bonusrunda kan jackpotten inte vinnas.",
"bonus_rounds_p5": "Spelarens ledighet kan resultera i att en aktiv bonusrunda avbryts. I det h\u00e4r fallet minskas antalet bonusrundor och spelarens saldo f\u00f6rblir of\u00f6r\u00e4ndrad.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Satsning redan aktiv',
};
langArr.tr = {
'loading': 'Yükleniyor',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Bonus Turlar\u0131",
"bonus_rounds_p1": "Bir oyuncuya rastgele bonus turlar\u0131 (yani \u00fccretsiz bahisler) verilebilir. Ekranda oyuncuya verilen bonus turlar\u0131, bahis say\u0131s\u0131 ve bahis ba\u015f\u0131na miktar hakk\u0131nda bildirimde bulunan bir a\u00e7\u0131l\u0131r grafik belirecektir.",
"bonus_rounds_p2": "\u00d6d\u00fcl verildikten sonra oyuncu bonus turunun ne zaman ba\u015flayaca\u011f\u0131n\u0131 se\u00e7ebilir. Bonus turlar\u0131 ba\u015flad\u0131ktan sonra, belirtilen bahis miktar\u0131yla otomatik bahis yap\u0131l\u0131r ve oyuncu istedi\u011fi anda paray\u0131 \u00e7ekmekle y\u00fck\u00fcml\u00fcd\u00fcr.",
"bonus_rounds_p3": "Bonus turlar\u0131n\u0131n bir zaman s\u0131n\u0131r\u0131 olabilir, bu s\u00fcreden sonra ge\u00e7ersiz ve kullan\u0131lamaz hale gelirler. Ge\u00e7erlilik s\u00fcresi varsa, bonus turunun alt\u0131nda g\u00f6sterilir.",
"bonus_rounds_p4": "Bonus turu jackpot fonuna katk\u0131da bulunmaz. Bonus turu s\u0131ras\u0131nda jackpot kazan\u0131lamaz.",
"bonus_rounds_p5": "Oyuncunun bo\u015fta kalmas\u0131 aktif bir bonus turunun iptaline neden olabilir. Bu durumda bonus turlar\u0131n\u0131n say\u0131s\u0131 azal\u0131r ve oyuncunun bakiyesi ayn\u0131 kal\u0131r.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bahis zaten aktif',
};
langArr.uk = {
'loading': 'Завантаження',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u0411\u043e\u043d\u0443\u0441\u043d\u0456 \u0440\u0430\u0443\u043d\u0434\u0438",
"bonus_rounds_p1": "\u0413\u0440\u0430\u0432\u0435\u0446\u044c \u043c\u043e\u0436\u0435 \u0432\u0438\u043f\u0430\u0434\u043a\u043e\u0432\u0438\u043c \u0447\u0438\u043d\u043e\u043c \u043e\u0442\u0440\u0438\u043c\u0430\u0442\u0438 \u0431\u043e\u043d\u0443\u0441\u043d\u0456 \u0440\u0430\u0443\u043d\u0434\u0438 (\u0442\u043e\u0431\u0442\u043e \u0431\u0435\u0437\u043a\u043e\u0448\u0442\u043e\u0432\u043d\u0456 \u0441\u0442\u0430\u0432\u043a\u0438). \u041d\u0430 \u0435\u043a\u0440\u0430\u043d\u0456 \u0437\u2019\u044f\u0432\u0438\u0442\u044c\u0441\u044f \u0441\u043f\u043b\u0438\u0432\u0430\u044e\u0447\u0435 \u0432\u0456\u043a\u043d\u043e, \u0449\u043e \u0441\u043f\u043e\u0432\u0456\u0449\u0430\u0442\u0438\u043c\u0435 \u0433\u0440\u0430\u0432\u0446\u044f \u043f\u0440\u043e \u043d\u0430\u0433\u043e\u0440\u043e\u0434\u0436\u0435\u043d\u0456 \u0431\u043e\u043d\u0443\u0441\u043d\u0456 \u0440\u0430\u0443\u043d\u0434\u0438, \u043a\u0456\u043b\u044c\u043a\u0456\u0441\u0442\u044c \u0441\u0442\u0430\u0432\u043e\u043a \u0456 \u0441\u0443\u043c\u0443 \u0441\u0442\u0430\u0432\u043a\u0438.",
"bonus_rounds_p2": "\u041e\u0442\u0440\u0438\u043c\u0430\u0432\u0448\u0438 \u043d\u0430\u0433\u043e\u0440\u043e\u0434\u0443, \u0433\u0440\u0430\u0432\u0435\u0446\u044c \u043c\u043e\u0436\u0435 \u0432\u0438\u0431\u0440\u0430\u0442\u0438, \u043a\u043e\u043b\u0438 \u043f\u043e\u0447\u0430\u0442\u0438 \u0431\u043e\u043d\u0443\u0441\u043d\u0438\u0439 \u0440\u0430\u0443\u043d\u0434. \u041f\u0456\u0441\u043b\u044f \u043f\u043e\u0447\u0430\u0442\u043a\u0443 \u0431\u043e\u043d\u0443\u0441\u043d\u0438\u0445 \u0440\u0430\u0443\u043d\u0434\u0456\u0432 \u0440\u043e\u0431\u0438\u0442\u044c\u0441\u044f \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u043d\u0430 \u0441\u0442\u0430\u0432\u043a\u0430 \u0437 \u0443\u043a\u0430\u0437\u0430\u043d\u043e\u044e \u0441\u0442\u0430\u0432\u043a\u043e\u044e, \u0456 \u0433\u0440\u0430\u0432\u0435\u0446\u044c \u043c\u043e\u0436\u0435 \u0432\u0438\u0432\u0435\u0441\u0442\u0438 \u0433\u0440\u043e\u0448\u0456 \u0432 \u0431\u0443\u0434\u044c-\u044f\u043a\u0438\u0439 \u043c\u043e\u043c\u0435\u043d\u0442.",
"bonus_rounds_p3": "\u0411\u043e\u043d\u0443\u0441\u043d\u0456 \u0440\u0430\u0443\u043d\u0434\u0438 \u043c\u043e\u0436\u0443\u0442\u044c \u043c\u0430\u0442\u0438 \u043e\u0431\u043c\u0435\u0436\u0435\u043d\u043d\u044f \u0437\u0430 \u0447\u0430\u0441\u043e\u043c, \u043f\u0456\u0441\u043b\u044f \u0437\u0430\u043a\u0456\u043d\u0447\u0435\u043d\u043d\u044f \u044f\u043a\u043e\u0433\u043e \u0432\u043e\u043d\u0438 \u0441\u0442\u0430\u044e\u0442\u044c \u043d\u0435\u0434\u0456\u0439\u0441\u043d\u0438\u043c\u0438 \u0442\u0430 \u043d\u0435\u043f\u0440\u0438\u0434\u0430\u0442\u043d\u0438\u043c\u0438 \u0434\u043b\u044f \u0432\u0438\u043a\u043e\u0440\u0438\u0441\u0442\u0430\u043d\u043d\u044f. \u0422\u0435\u0440\u043c\u0456\u043d \u0434\u0456\u0457, \u044f\u043a\u0449\u043e \u0442\u0430\u043a\u0438\u0439 \u0454, \u0432\u0456\u0434\u043e\u0431\u0440\u0430\u0436\u0430\u0454\u0442\u044c\u0441\u044f \u043f\u0456\u0434 \u0431\u043e\u043d\u0443\u0441\u043d\u0438\u043c \u0440\u0430\u0443\u043d\u0434\u043e\u043c.",
"bonus_rounds_p4": "\u0411\u043e\u043d\u0443\u0441\u043d\u0438\u0439 \u0440\u0430\u0443\u043d\u0434 \u043d\u0435 \u0441\u043f\u0440\u0438\u044f\u0454 \u043d\u0430\u043a\u043e\u043f\u0438\u0447\u0435\u043d\u043d\u044e \u0434\u0436\u0435\u043a\u043f\u043e\u0442\u0443. \u041f\u0456\u0434 \u0447\u0430\u0441 \u0431\u043e\u043d\u0443\u0441\u043d\u043e\u0433\u043e \u0440\u0430\u0443\u043d\u0434\u0443 \u0434\u0436\u0435\u043a\u043f\u043e\u0442 \u043d\u0435 \u043c\u043e\u0436\u043d\u0430 \u0432\u0438\u0433\u0440\u0430\u0442\u0438.",
"bonus_rounds_p5": "\u0411\u0435\u0437\u0434\u0456\u044f\u043b\u044c\u043d\u0456\u0441\u0442\u044c \u0433\u0440\u0430\u0432\u0446\u044f \u043c\u043e\u0436\u0435 \u043f\u0440\u0438\u0437\u0432\u0435\u0441\u0442\u0438 \u0434\u043e \u0441\u043a\u0430\u0441\u0443\u0432\u0430\u043d\u043d\u044f \u0430\u043a\u0442\u0438\u0432\u043d\u043e\u0433\u043e \u0431\u043e\u043d\u0443\u0441\u043d\u043e\u0433\u043e \u0440\u0430\u0443\u043d\u0434\u0443. \u0423 \u0446\u044c\u043e\u043c\u0443 \u0432\u0438\u043f\u0430\u0434\u043a\u0443 \u043a\u0456\u043b\u044c\u043a\u0456\u0441\u0442\u044c \u0431\u043e\u043d\u0443\u0441\u043d\u0438\u0445 \u0440\u0430\u0443\u043d\u0434\u0456\u0432 \u0437\u043c\u0435\u043d\u0448\u0443\u0454\u0442\u044c\u0441\u044f, \u0430 \u0431\u0430\u043b\u0430\u043d\u0441 \u0433\u0440\u0430\u0432\u0446\u044f \u0437\u0430\u043b\u0438\u0448\u0430\u0454\u0442\u044c\u0441\u044f \u043d\u0435\u0437\u043c\u0456\u043d\u043d\u0438\u043c.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Ставка вже активна',
};
langArr.sr = {
'loading': 'Učitavanje',
'hotkeys': 'Prečice',
'hotkeys_enabled': 'Prečice aktivne',
'enable_hotkeys_checkbox': 'Omogući prečice aktiviranjem ove opcije.',
'make_bet': 'Uplati tiket',
'double_bet': 'Dupliraj ulog',
'halve_bet': 'Prepolovi ulog',
'min_bet': 'Minimalni ulog',
'cashout_key': 'Isplata',
'walk_next_line': 'Pređi u sledeću traku',
'max': 'Max',
'easy': 'Lako',
'medium': 'Osrednje',
'hard': 'Teško',
'daredevil': 'Izazovno',
'max_profit': 'Max Profit',
'manual': 'Ručno',
'auto': 'Automatski',
'bet_amount': 'Iznos opklade',
'difficulty': 'Težina',
'payout_on_win': 'Isplata po dobitku',
'number_of_bets': 'Broj opklada',
'on_win': 'po dobitku',
'on_loss': 'po gubitku',
'stop_on_profit': 'Prekini na profitu',
'stop_on_loss': 'Prekini na gubitku',
'reset': 'Reset',
'increase_by': 'Povećaj za',
'cashout': 'ISPLATI TIKET',
'start_game': 'UPLATI TIKET',
'start_auto_game': 'START',
'difficulity_info': 'Težina igre',
'chance_collision': 'Šansa za sudar po igri',
'evry_25_lane': 'za svih 25 traka',
'insufficiend_funds': 'Nemate dovoljno novca',
'cashout_inactivity': 'Isplata usled neaktivnosti',
'time_running_out': 'Vreme ističe! Igraj odmah ili će tvoj tiket biti isplaćen u narednih 30 sekundi',
'there_was_error': 'Dogodila se greška! Učitaj igru ponovo.',
'detailed_game_rules': 'Detaljna Pravila Igre',
'welcome': 'Dobrodošli u Soccer Rush - novu i uzbudljivu igru!',
'general_rules': 'OPŠTA PRAVILA',
'general_rules_p1': '• Množilac počinje od 1 i ide do ',
'general_rules_p2': '• Dobitak se izračunava množenjem opklade sa množiteljem u trenutku klika na dugme ISPLATI TIKET',
'general_rules_p3': '• Ako je dostignut maksimalni dobitak opklada se automatski unovčava',
'progressive_jackpot': 'PROGRESIVNI DŽEKPOT',
'progressive_jackpot_p1': '1% svakog uloga se izdvaja u fond za progresivni džekpot.',
'progressive_jackpot_p2': 'Džekpot se dodeljuje putem mehanike "Mistery" ili "must-hit-by". Vrednost svakog "must-hit-by" džekpota se određuje odmah nakon što prethodni džekpot bude osvojen, koristeći generator slučajnih brojeva. Ta vrednost se čuva kao šifrovani broj u udaljenoj bazi podataka, što znači da je skrivena i nepoznata.',
'progressive_jackpot_p3': 'Kada se dostigne vrednost "must-hit-by", prvi igrač koji uloži nakon toga imaće priliku da osvoji džekpot. Simbol džekpota će se pojaviti na nasumičnoj traci, a džekpot se osvaja ako igrač stigne do trake sa džekpot simbolom. Ako igrač ne stigne do trake sa simbolom džekpota zbog isplate ili sudara, sledeći igrač koji uloži videće simbol džekpota i imati priliku da ga osvoji. Ovaj proces se ponavlja dok džekpot ne bude osvojen.',
'showdown': 'OTKRIVANJE',
'showdown_p1': 'Nakon izvršene isplate, crveni kvadrat će biti prikazan na poziciji gde bi se sudar dogodio da je igrač nastavio sa igrom.',
'return_to_player': 'POVRAĆAJ IGRAČU',
'return_to_player_p1': 'Povraćaj igraču (RTP), uključujući vraćanje igara i džekpota, postavljen je na 98% od čega 97% dolazi direktno iz igre, a dodatnih 1.00% iz džekpota.',
'return_to_player_p2': 'Procente RTP -a stalno prate i verifikuju nezavisne treće strane.',
'disconnection_policy': 'POLITIKA POVEZIVANJA',
'disconnection_policy_p1': 'Ako se veza prekine nakon postavljanja opklade, opklada se neće poništiti. Ako je igrač neaktivan više od ',
'disconnection_policy_p2': 's opklada će biti automatski isplaćena i krediti će biti primenjeni na vaš račun.',
'network_latency': 'KAŠNJENJA U MREŽI',
'network_latency_p1': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani.',
'sound': 'ZVUK',
'sound_p1': 'Soccer Rush je dizajniran sa prelepom muzikom u pozadini i zvučnim efektima. Ako želite da onemogućite muziku i/ili zvučne efekte, to možete učiniti putem ikona za kontrolu zvučnih efekata (1) i zvuka (2) koje se nalaze u donjem desnom uglu ekrana.',
'error_handling': 'U SLUČAJU GREŠKE',
'error_handling_p1': 'U slučaju greške naš tim za podršku će pokušati rešiti problem što je pre moguće. U slučaju problema sa samom igrom, sve opklade će biti poništene, a iznos uloga vraćen igračima.',
'max_payout': 'Maksimalna isplata je ',
'max_bet_is': 'Maksimalni ulog je ',
'min_bet_is': 'Minimalni ulog je ',
'per_bet': 'po opkladi.',
'malfunction_voids': 'Neispravnost u radu poništava sve isplate i igre.',
'attention_jackpot': 'Pažnja, Džekpot na traci ',
'lane': 'traka',
'congratulations': 'bravo',
'you_won': 'osvojio si',
'balance': 'balans',
'time': 'vreme',
'jackpot': 'džekpot',
'escaped': 'POBEGAO',
'max_win': 'MAX DOBITAK',
'check_internet': 'Molimo proverite internet konekciju',
'websocket_disconected': 'Websocket diskonektovan',
'websocket_connection_error': 'Websocket greska u konekciji !',
'session_expired': 'Sesija je istekla',
'remaining_bets': 'Preostale opklade',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTO-IGRA I AUTO-ISPLATA',
'autoplay_and_autocashout_p1': 'Igrač može aktivirati opciju AUTO-IGRA gde se opklada automatski stavlja za svaku novu rundu koristeći iznos opklade iz prethodne runde.',
'autoplay_and_autocashout_p2': 'Odabir množioca za automatsku isplatu se vrši sa ekrana igre. Kada je množilac podešen, igra će automatski izvršiti isplatu ako igrač ne bude udaren pre nego što dostigne izabrani množilac.',
'autoplay_button_label': 'Za pocetak, izaberi traku.',
'min_bet_amount_is': 'Min iznos opklade je',
'bonus_rounds': 'bonus runde',
'bonus_rounds_p1': 'Igrač može biti nasumično nagrađen besplatnim opkladama u vidu bonus rundi. Na ekranu će iskočiti slika koja obaveštava igrača o dodeljenim besplatnim rundama, broju rundi i iznosu po opkladi.',
'bonus_rounds_p2': 'Nakon što se dodele bonus runde, igrač može izabrati kada počinje besplatnu rundu opklade. Nakon što započne runda besplatnih opklada, postavlja se automatska opklada sa navedenim ulogom, a na igraču je da isplati u svakom trenutku.',
'bonus_rounds_p3': 'Besplatne opklade mogu imati rok važenja nakon čega ističu i ne mogu se koristiti. Rok važenja, ako postoji, je prikazan ispod bonus rundi.',
'bonus_rounds_p4': 'Besplatne opklade ne učestvuju u jackpot fondu. Jackpot nije moguće osvojiti u bonus rundi.',
'bonus_rounds_p5': 'Neaktivnost igrača moze prouzrokovati otkazivanje aktivne bonus runde. U tom slučaju, broj bonus rundi se smanjuje a balans igrača ostaje nepromenjen.',
'valid_until': 'važi do',
'bonus': 'bonus',
'rounds': 'runda',
'win_eur': 'dobitak EUR',
'complete_your_bet': 'Prvo završite vašu opkladu',
'complete_auto_play': 'Prvo završite vašu auto-igru',
'yes': 'da',
'no': 'ne',
'play_bonus_rounds': 'Igraj Bonus runde?',
'bet_history': 'Istorija opklada',
'jackpot_history': 'Istorija džekpota',
'ticket_id': 'Ticket ID',
'created': 'vreme kreiranja',
'currency': 'valuta',
'bet': 'ulog',
'bet_eur': 'ulog EUR',
'lanes': 'trake',
'cars': 'automobili',
'multiplier': 'množitelj',
'win': 'dobitak',
'no_betting_history': 'Istorija opklada nije pronađena. Napravite opkladu i ona će se pojaviti ovde.',
'no_jackpot_history': 'Nema dostupne istorije džekpota.',
'date': 'datum',
'user': 'igrač',
'bet_already_active': 'Opklada je već aktivna',
};
langArr.rs = {
'loading': 'Učitavanje',
'hotkeys': 'Prečice',
'hotkeys_enabled': 'Prečice aktivne',
'enable_hotkeys_checkbox': 'Omogući prečice aktiviranjem ove opcije.',
'make_bet': 'Uplati tiket',
'double_bet': 'Dupliraj ulog',
'halve_bet': 'Prepolovi ulog',
'min_bet': 'Minimalni ulog',
'cashout_key': 'Isplata',
'walk_next_line': 'Pređi u sledeću traku',
'max': 'Max',
'easy': 'Lako',
'medium': 'Osrednje',
'hard': 'Teško',
'daredevil': 'Izazovno',
'max_profit': 'Max Profit',
'manual': 'Ručno',
'auto': 'Automatski',
'bet_amount': 'Iznos opklade',
'difficulty': 'Težina',
'payout_on_win': 'Isplata po dobitku',
'number_of_bets': 'Broj opklada',
'on_win': 'po dobitku',
'on_loss': 'po gubitku',
'stop_on_profit': 'Prekini na profitu',
'stop_on_loss': 'Prekini na gubitku',
'reset': 'Reset',
'increase_by': 'Povećaj za',
'cashout': 'ISPLATI TIKET',
'start_game': 'UPLATI TIKET',
'start_auto_game': 'START',
'difficulity_info': 'Težina igre',
'chance_collision': 'Šansa za sudar po igri',
'evry_25_lane': 'za svih 25 traka',
'insufficiend_funds': 'Nemate dovoljno novca',
'cashout_inactivity': 'Isplata usled neaktivnosti',
'time_running_out': 'Vreme ističe! Igraj odmah ili će tvoj tiket biti isplaćen u narednih 30 sekundi',
'there_was_error': 'Dogodila se greška! Učitaj igru ponovo.',
'detailed_game_rules': 'Detaljna Pravila Igre',
'welcome': 'Dobrodošli u Soccer Rush - novu i uzbudljivu igru!',
'general_rules': 'OPŠTA PRAVILA',
'general_rules_p1': '• Množilac počinje od 1 i ide do ',
'general_rules_p2': '• Dobitak se izračunava množenjem opklade sa množiteljem u trenutku klika na dugme ISPLATI TIKET',
'general_rules_p3': '• Ako je dostignut maksimalni dobitak opklada se automatski unovčava',
'progressive_jackpot': 'PROGRESIVNI DŽEKPOT',
'progressive_jackpot_p1': '1% svakog uloga se izdvaja u fond za progresivni džekpot.',
'progressive_jackpot_p2': 'Džekpot se dodeljuje putem mehanike "Mistery" ili "must-hit-by". Vrednost svakog "must-hit-by" džekpota se određuje odmah nakon što prethodni džekpot bude osvojen, koristeći generator slučajnih brojeva. Ta vrednost se čuva kao šifrovani broj u udaljenoj bazi podataka, što znači da je skrivena i nepoznata.',
'progressive_jackpot_p3': 'Kada se dostigne vrednost "must-hit-by", prvi igrač koji uloži nakon toga imaće priliku da osvoji džekpot. Simbol džekpota će se pojaviti na nasumičnoj traci, a džekpot se osvaja ako igrač stigne do trake sa džekpot simbolom. Ako igrač ne stigne do trake sa simbolom džekpota zbog isplate ili sudara, sledeći igrač koji uloži videće simbol džekpota i imati priliku da ga osvoji. Ovaj proces se ponavlja dok džekpot ne bude osvojen.',
'showdown': 'OTKRIVANJE',
'showdown_p1': 'Nakon izvršene isplate, crveni kvadrat će biti prikazan na poziciji gde bi se sudar dogodio da je igrač nastavio sa igrom.',
'return_to_player': 'POVRAĆAJ IGRAČU',
'return_to_player_p1': 'Povraćaj igraču (RTP), uključujući vraćanje igara i džekpota, postavljen je na 98% od čega 97% dolazi direktno iz igre, a dodatnih 1.00% iz džekpota.',
'return_to_player_p2': 'Procente RTP -a stalno prate i verifikuju nezavisne treće strane.',
'disconnection_policy': 'POLITIKA POVEZIVANJA',
'disconnection_policy_p1': 'Ako se veza prekine nakon postavljanja opklade, opklada se neće poništiti. Ako je igrač neaktivan više od ',
'disconnection_policy_p2': 's opklada će biti automatski isplaćena i krediti će biti primenjeni na vaš račun.',
'network_latency': 'KAŠNJENJA U MREŽI',
'network_latency_p1': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani.',
'sound': 'ZVUK',
'sound_p1': 'Soccer Rush je dizajniran sa prelepom muzikom u pozadini i zvučnim efektima. Ako želite da onemogućite muziku i/ili zvučne efekte, to možete učiniti putem ikona za kontrolu zvučnih efekata (1) i zvuka (2) koje se nalaze u donjem desnom uglu ekrana.',
'error_handling': 'U SLUČAJU GREŠKE',
'error_handling_p1': 'U slučaju greške naš tim za podršku će pokušati rešiti problem što je pre moguće. U slučaju problema sa samom igrom, sve opklade će biti poništene, a iznos uloga vraćen igračima.',
'max_payout': 'Maksimalna isplata je ',
'max_bet_is': 'Maksimalni ulog je ',
'min_bet_is': 'Minimalni ulog je ',
'per_bet': 'po opkladi.',
'malfunction_voids': 'Neispravnost u radu poništava sve isplate i igre.',
'attention_jackpot': 'Pažnja, Džekpot na traci ',
'lane': 'traka',
'congratulations': 'bravo',
'you_won': 'osvojio si',
'balance': 'balans',
'time': 'vreme',
'jackpot': 'džekpot',
'escaped': 'POBEGAO',
'max_win': 'MAX DOBITAK',
'check_internet': 'Molimo proverite internet konekciju',
'websocket_disconected': 'Websocket diskonektovan',
'websocket_connection_error': 'Websocket greska u konekciji !',
'session_expired': 'Sesija je istekla',
'remaining_bets': 'Preostale opklade',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTO-IGRA I AUTO-ISPLATA',
'autoplay_and_autocashout_p1': 'Igrač može aktivirati opciju AUTO-IGRA gde se opklada automatski stavlja za svaku novu rundu koristeći iznos opklade iz prethodne runde.',
'autoplay_and_autocashout_p2': 'Odabir množioca za automatsku isplatu se vrši sa ekrana igre. Kada je množilac podešen, igra će automatski izvršiti isplatu ako igrač ne bude udaren pre nego što dostigne izabrani množilac.',
'autoplay_button_label': 'Za pocetak, izaberi traku.',
'min_bet_amount_is': 'Min iznos opklade je',
'bonus_rounds': 'bonus runde',
'bonus_rounds_p1': 'Igrač može biti nasumično nagrađen besplatnim opkladama u vidu bonus rundi. Na ekranu će iskočiti slika koja obaveštava igrača o dodeljenim besplatnim rundama, broju rundi i iznosu po opkladi.',
'bonus_rounds_p2': 'Nakon što se dodele bonus runde, igrač može izabrati kada počinje besplatnu rundu opklade. Nakon što započne runda besplatnih opklada, postavlja se automatska opklada sa navedenim ulogom, a na igraču je da isplati u svakom trenutku.',
'bonus_rounds_p3': 'Besplatne opklade mogu imati rok važenja nakon čega ističu i ne mogu se koristiti. Rok važenja, ako postoji, je prikazan ispod bonus rundi.',
'bonus_rounds_p4': 'Besplatne opklade ne učestvuju u jackpot fondu. Jackpot nije moguće osvojiti u bonus rundi.',
'bonus_rounds_p5': 'Neaktivnost igrača moze prouzrokovati otkazivanje aktivne bonus runde. U tom slučaju, broj bonus rundi se smanjuje a balans igrača ostaje nepromenjen.',
'valid_until': 'važi do',
'bonus': 'bonus',
'rounds': 'runda',
'win_eur': 'dobitak EUR',
'complete_your_bet': 'Prvo završite vašu opkladu',
'complete_auto_play': 'Prvo završite vašu auto-igru',
'yes': 'da',
'no': 'ne',
'play_bonus_rounds': 'Igraj Bonus runde?',
'bet_history': 'Istorija opklada',
'jackpot_history': 'Istorija džekpota',
'ticket_id': 'Ticket ID',
'created': 'vreme kreiranja',
'currency': 'valuta',
'bet': 'ulog',
'bet_eur': 'ulog EUR',
'lanes': 'trake',
'cars': 'automobili',
'multiplier': 'množitelj',
'win': 'dobitak',
'no_betting_history': 'Istorija opklada nije pronađena. Napravite opkladu i ona će se pojaviti ovde.',
'no_jackpot_history': 'Nema dostupne istorije džekpota.',
'date': 'datum',
'user': 'igrač',
'bet_already_active': 'Opklada je već aktivna',
};
langArr.zh = {
'loading': '加载中',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u5956\u52b1\u56de\u5408",
"bonus_rounds_p1": "\u73a9\u5bb6\u53ef\u80fd\u4f1a\u968f\u673a\u83b7\u5f97\u5956\u52b1\u56de\u5408\uff08\u5373\u514d\u8d39\u6295\u6ce8\uff09\u3002\u5c4f\u5e55\u4e0a\u4f1a\u51fa\u73b0\u4e00\u4e2a\u5f39\u51fa\u56fe\u5f62\uff0c\u901a\u77e5\u73a9\u5bb6\u5956\u52b1\u56de\u5408\u3001\u6295\u6ce8\u6b21\u6570\u548c\u6bcf\u6b21\u6295\u6ce8\u91d1\u989d\u3002",
"bonus_rounds_p2": "\u4e00\u65e6\u4e2d\u5956\uff0c\u73a9\u5bb6\u53ef\u4ee5\u9009\u62e9\u4f55\u65f6\u5f00\u59cb\u5956\u52b1\u56de\u5408\u3002\u4e00\u65e6\u5956\u52b1\u56de\u5408\u5f00\u59cb\uff0c\u5c31\u4f1a\u81ea\u52a8\u4e0b\u6ce8\u6307\u5b9a\u7684\u8d4c\u6ce8\uff0c\u73a9\u5bb6\u53ef\u4ee5\u968f\u65f6\u5151\u73b0\u3002",
"bonus_rounds_p3": "\u5956\u52b1\u56de\u5408\u53ef\u80fd\u6709\u65f6\u95f4\u9650\u5236\uff0c\u8d85\u8fc7\u65f6\u95f4\u9650\u5236\uff0c\u5956\u52b1\u56de\u5408\u5c06\u5931\u6548\u4e14\u65e0\u6cd5\u4f7f\u7528\u3002\u6709\u6548\u671f\uff08\u5982\u679c\u6709\uff09\u663e\u793a\u5728\u5956\u52b1\u56de\u5408\u4e0b\u65b9\u3002",
"bonus_rounds_p4": "\u5956\u52b1\u56de\u5408\u4e0d\u4f1a\u589e\u52a0\u7d2f\u79ef\u5956\u91d1\u3002\u5956\u52b1\u56de\u5408\u671f\u95f4\u65e0\u6cd5\u8d62\u5f97\u7d2f\u79ef\u5956\u91d1\u3002",
"bonus_rounds_p5": "\u73a9\u5bb6\u95f2\u7f6e\u53ef\u80fd\u5bfc\u81f4\u5f53\u524d\u5956\u52b1\u56de\u5408\u88ab\u53d6\u6d88\u3002\u5728\u8fd9\u79cd\u60c5\u51b5\u4e0b\uff0c\u5956\u52b1\u56de\u5408\u6570\u4f1a\u51cf\u5c11\uff0c\u800c\u73a9\u5bb6\u7684\u4f59\u989d\u4fdd\u6301\u4e0d\u53d8\u3002",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': '投注已激活',
};
langArr.hi = {
'loading': 'लोड हो रहा है',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921",
"bonus_rounds_p1": "\u0915\u093f\u0938\u0940 \u0916\u093f\u0932\u093e\u0921\u093c\u0940 \u0915\u094b \u092c\u0947\u0924\u0930\u0924\u0940\u092c \u0922\u0902\u0917 \u0938\u0947 \u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921 (\u092f\u093e\u0928\u0940 \u092e\u0941\u092b\u093c\u094d\u0924 \u0926\u093e\u0902\u0935) \u0926\u093f\u090f \u091c\u093e \u0938\u0915\u0924\u0947 \u0939\u0948\u0902\u0964 \u0938\u094d\u0915\u094d\u0930\u0940\u0928 \u092a\u0930 \u090f\u0915 \u092a\u0949\u092a\u0905\u092a \u0917\u094d\u0930\u093e\u092b\u093c\u093f\u0915 \u0926\u093f\u0916\u093e\u0908 \u0926\u0947\u0917\u093e \u091c\u094b \u0916\u093f\u0932\u093e\u0921\u093c\u0940 \u0915\u094b \u0926\u093f\u090f \u0917\u090f \u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921, \u0926\u093e\u0902\u0935\u094b\u0902 \u0915\u0940 \u0938\u0902\u0916\u094d\u092f\u093e \u0914\u0930 \u092a\u094d\u0930\u0924\u093f \u0926\u093e\u0902\u0935 \u0930\u093e\u0936\u093f \u0915\u0947 \u092c\u093e\u0930\u0947 \u092e\u0947\u0902 \u0938\u0942\u091a\u093f\u0924 \u0915\u0930\u0947\u0917\u093e\u0964",
"bonus_rounds_p2": "\u090f\u0915 \u092c\u093e\u0930 \u091c\u092c \u0909\u0928\u094d\u0939\u0947\u0902 \u092a\u0941\u0930\u0938\u094d\u0915\u093e\u0930 \u092e\u093f\u0932 \u091c\u093e\u0924\u093e \u0939\u0948 \u0924\u094b \u0916\u093f\u0932\u093e\u0921\u093c\u0940 \u091a\u0941\u0928 \u0938\u0915\u0924\u093e \u0939\u0948 \u0915\u093f \u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921 \u0915\u092c \u0936\u0941\u0930\u0942 \u0915\u0930\u0928\u093e \u0939\u0948\u0964 \u090f\u0915 \u092c\u093e\u0930 \u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921 \u0936\u0941\u0930\u0942 \u0939\u094b \u091c\u093e\u0928\u0947 \u092a\u0930, \u0928\u093f\u0930\u094d\u0926\u093f\u0937\u094d\u091f \u0926\u093e\u0902\u0935 \u0915\u0947 \u0938\u093e\u0925 \u0938\u094d\u0935\u091a\u093e\u0932\u093f\u0924 \u0926\u093e\u0902\u0935 \u0932\u0917\u093e\u092f\u093e \u091c\u093e\u0924\u093e \u0939\u0948, \u0914\u0930 \u0915\u093f\u0938\u0940 \u092d\u0940 \u0938\u092e\u092f \u0915\u0948\u0936\u0906\u0909\u091f \u0915\u0930\u0928\u093e \u0916\u093f\u0932\u093e\u0921\u093c\u0940 \u092a\u0930 \u0928\u093f\u0930\u094d\u092d\u0930 \u0915\u0930\u0924\u093e \u0939\u0948\u0964",
"bonus_rounds_p3": "\u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921 \u0915\u0940 \u090f\u0915 \u0938\u092e\u092f \u0938\u0940\u092e\u093e \u0939\u094b \u0938\u0915\u0924\u0940 \u0939\u0948, \u091c\u093f\u0938\u0915\u0947 \u092c\u093e\u0926 \u0935\u0947 \u0905\u092e\u093e\u0928\u094d\u092f \u0914\u0930 \u0905\u0928\u0941\u092a\u092f\u094b\u0917\u0940 \u0939\u094b \u091c\u093e\u0924\u0947 \u0939\u0948\u0902\u0964 \u0935\u0948\u0927\u0924\u093e \u0905\u0935\u0927\u093f, \u092f\u0926\u093f \u0915\u094b\u0908 \u0939\u094b, \u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921 \u0915\u0947 \u0928\u0940\u091a\u0947 \u092a\u094d\u0930\u0926\u0930\u094d\u0936\u093f\u0924 \u0915\u0940 \u091c\u093e\u0924\u0940 \u0939\u0948\u0964",
"bonus_rounds_p4": "\u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921 \u091c\u0948\u0915\u092a\u0949\u091f \u092b\u0902\u0921 \u092e\u0947\u0902 \u092f\u094b\u0917\u0926\u093e\u0928 \u0928\u0939\u0940\u0902 \u0915\u0930\u0924\u093e \u0939\u0948\u0964 \u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921 \u0915\u0947 \u0926\u094c\u0930\u093e\u0928 \u091c\u0948\u0915\u092a\u0949\u091f \u0928\u0939\u0940\u0902 \u091c\u0940\u0924\u093e \u091c\u093e \u0938\u0915\u0924\u093e \u0939\u0948\u0964",
"bonus_rounds_p5": "\u0916\u093f\u0932\u093e\u0921\u093c\u0940 \u0915\u0947 \u0928\u093f\u0937\u094d\u0915\u094d\u0930\u093f\u092f \u0930\u0939\u0928\u0947 \u0915\u0947 \u0915\u093e\u0930\u0923 \u0938\u0915\u094d\u0930\u093f\u092f \u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921 \u0930\u0926\u094d\u0926 \u0939\u094b \u0938\u0915\u0924\u093e \u0939\u0948\u0964 \u0907\u0938 \u0938\u094d\u0925\u093f\u0924\u093f \u092e\u0947\u0902, \u092c\u094b\u0928\u0938 \u0930\u093e\u0909\u0902\u0921 \u0915\u0940 \u0938\u0902\u0916\u094d\u092f\u093e \u0915\u092e \u0939\u094b \u091c\u093e\u0924\u0940 \u0939\u0948 \u0914\u0930 \u0916\u093f\u0932\u093e\u0921\u093c\u0940 \u0915\u093e \u092c\u0948\u0932\u0947\u0902\u0938 \u0935\u0939\u0940 \u0930\u0939\u0924\u093e \u0939\u0948\u0964",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'शर्त पहले से ही सक्रिय है',
};
langArr.cs = {
'loading': 'Načítání',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Bonusov\u00e1 kola",
"bonus_rounds_p1": "Hr\u00e1\u010d m\u016f\u017ee b\u00fdt n\u00e1hodn\u011b ocen\u011bn bonusov\u00fdmi koly (tj. s\u00e1zkami zdarma). Na obrazovce se objev\u00ed vyskakovac\u00ed grafika informuj\u00edc\u00ed hr\u00e1\u010de o ud\u011blen\u00fdch bonusov\u00fdch kolech, po\u010dtu s\u00e1zek a \u010d\u00e1stce na s\u00e1zku.",
"bonus_rounds_p2": "Jakmile jsou ud\u011bleny, hr\u00e1\u010d si m\u016f\u017ee vybrat, kdy za\u010dne bonusov\u00e9 kolo. Jakmile jsou bonusov\u00e1 kola zah\u00e1jena, je um\u00edst\u011bna automatick\u00e1 s\u00e1zka s ur\u010den\u00fdm vkladem a je na hr\u00e1\u010di, aby v kteroukoli chv\u00edli provedl v\u00fdb\u011br.",
"bonus_rounds_p3": "Bonusov\u00e1 kola mohou m\u00edt \u010dasov\u00fd limit, po jeho\u017e uplynut\u00ed se stanou neplatn\u00fdmi a nepou\u017eiteln\u00fdmi. Doba platnosti, pokud existuje, je zobrazena pod bonusov\u00fdm kolem.",
"bonus_rounds_p4": "Bonusov\u00e9 kolo nep\u0159isp\u00edv\u00e1 do jackpotov\u00e9ho fondu. B\u011bhem bonusov\u00e9ho kola nelze vyhr\u00e1t jackpot.",
"bonus_rounds_p5": "Ne\u010dinnost hr\u00e1\u010de m\u016f\u017ee m\u00edt za n\u00e1sledek zru\u0161en\u00ed aktivn\u00edho bonusov\u00e9ho kola. V tomto p\u0159\u00edpad\u011b se po\u010det bonusov\u00fdch kol sn\u00ed\u017e\u00ed a z\u016fstatek hr\u00e1\u010de z\u016fstane stejn\u00fd.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Sázka je již aktivní',
};
langArr.id = {
'loading': 'Memuat',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Babak Bonus",
"bonus_rounds_p1": "Seorang pemain dapat secara acak diberi hadiah ronde bonus (misalnya taruhan gratis). Grafik pop-up akan muncul di layar yang memberitahukan pemain tentang ronde bonus yang diberikan, jumlah taruhan, dan jumlah per taruhan.",
"bonus_rounds_p2": "Setelah bonus diberikan, pemain dapat memilih kapan memulai putaran bonus. Setelah putaran bonus dimulai, taruhan otomatis dengan jumlah taruhan yang ditentukan akan dipasang, dan terserah kepada pemain untuk mencairkan dana kapan saja.",
"bonus_rounds_p3": "Ronde bonus dapat memiliki batas waktu, setelah itu ronde tersebut menjadi batal dan tidak dapat digunakan. Periode validitas, jika ada, ditampilkan di bawah ronde bonus.",
"bonus_rounds_p4": "Putaran bonus tidak berkontribusi pada dana jackpot. Selama putaran bonus, jackpot tidak dapat dimenangkan.",
"bonus_rounds_p5": "Ketidakaktifan pemain dapat mengakibatkan pembatalan ronde bonus yang sedang berlangsung. Dalam kasus ini, jumlah ronde bonus akan berkurang dan saldo pemain tetap sama.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Taruhan sudah aktif',
};
langArr.th = {
'loading': 'กำลังโหลด',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "\u0e23\u0e2d\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a",
"bonus_rounds_p1": "\u0e1c\u0e39\u0e49\u0e40\u0e25\u0e48\u0e19\u0e2d\u0e32\u0e08\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e23\u0e32\u0e07\u0e27\u0e31\u0e25\u0e42\u0e1a\u0e19\u0e31\u0e2a\u0e23\u0e2d\u0e1a\u0e41\u0e1a\u0e1a\u0e2a\u0e38\u0e48\u0e21 (\u0e40\u0e0a\u0e48\u0e19 \u0e40\u0e14\u0e34\u0e21\u0e1e\u0e31\u0e19\u0e1f\u0e23\u0e35) \u0e01\u0e23\u0e32\u0e1f\u0e34\u0e01\u0e1b\u0e4a\u0e2d\u0e1b\u0e2d\u0e31\u0e1b\u0e08\u0e30\u0e1b\u0e23\u0e32\u0e01\u0e0f\u0e1a\u0e19\u0e2b\u0e19\u0e49\u0e32\u0e08\u0e2d\u0e40\u0e1e\u0e37\u0e48\u0e2d\u0e41\u0e08\u0e49\u0e07\u0e43\u0e2b\u0e49\u0e1c\u0e39\u0e49\u0e40\u0e25\u0e48\u0e19\u0e17\u0e23\u0e32\u0e1a\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e01\u0e31\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a\u0e23\u0e2d\u0e1a\u0e17\u0e35\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a \u0e08\u0e33\u0e19\u0e27\u0e19\u0e01\u0e32\u0e23\u0e40\u0e14\u0e34\u0e21\u0e1e\u0e31\u0e19 \u0e41\u0e25\u0e30\u0e08\u0e33\u0e19\u0e27\u0e19\u0e40\u0e07\u0e34\u0e19\u0e15\u0e48\u0e2d\u0e01\u0e32\u0e23\u0e40\u0e14\u0e34\u0e21\u0e1e\u0e31\u0e19",
"bonus_rounds_p2": "\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e23\u0e32\u0e07\u0e27\u0e31\u0e25\u0e41\u0e25\u0e49\u0e27 \u0e1c\u0e39\u0e49\u0e40\u0e25\u0e48\u0e19\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e14\u0e49\u0e27\u0e48\u0e32\u0e08\u0e30\u0e40\u0e23\u0e34\u0e48\u0e21\u0e23\u0e2d\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e43\u0e14 \u0e40\u0e21\u0e37\u0e48\u0e2d\u0e23\u0e2d\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a\u0e40\u0e23\u0e34\u0e48\u0e21\u0e02\u0e36\u0e49\u0e19 \u0e08\u0e30\u0e21\u0e35\u0e01\u0e32\u0e23\u0e27\u0e32\u0e07\u0e40\u0e14\u0e34\u0e21\u0e1e\u0e31\u0e19\u0e2d\u0e31\u0e15\u0e42\u0e19\u0e21\u0e31\u0e15\u0e34\u0e14\u0e49\u0e27\u0e22\u0e40\u0e07\u0e34\u0e19\u0e40\u0e14\u0e34\u0e21\u0e1e\u0e31\u0e19\u0e17\u0e35\u0e48\u0e01\u0e33\u0e2b\u0e19\u0e14 \u0e41\u0e25\u0e30\u0e1c\u0e39\u0e49\u0e40\u0e25\u0e48\u0e19\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e16\u0e2d\u0e19\u0e40\u0e07\u0e34\u0e19\u0e2d\u0e2d\u0e01\u0e44\u0e14\u0e49\u0e15\u0e25\u0e2d\u0e14\u0e40\u0e27\u0e25\u0e32",
"bonus_rounds_p3": "\u0e23\u0e2d\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a\u0e2d\u0e32\u0e08\u0e21\u0e35\u0e23\u0e30\u0e22\u0e30\u0e40\u0e27\u0e25\u0e32\u0e08\u0e33\u0e01\u0e31\u0e14 \u0e2b\u0e25\u0e31\u0e07\u0e08\u0e32\u0e01\u0e19\u0e31\u0e49\u0e19\u0e08\u0e30\u0e16\u0e37\u0e2d\u0e40\u0e1b\u0e47\u0e19\u0e42\u0e21\u0e06\u0e30\u0e41\u0e25\u0e30\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e44\u0e14\u0e49 \u0e0a\u0e48\u0e27\u0e07\u0e40\u0e27\u0e25\u0e32\u0e17\u0e35\u0e48\u0e43\u0e0a\u0e49\u0e44\u0e14\u0e49 (\u0e16\u0e49\u0e32\u0e21\u0e35) \u0e08\u0e30\u0e41\u0e2a\u0e14\u0e07\u0e2d\u0e22\u0e39\u0e48\u0e43\u0e15\u0e49\u0e23\u0e2d\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a",
"bonus_rounds_p4": "\u0e23\u0e2d\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a\u0e08\u0e30\u0e44\u0e21\u0e48\u0e23\u0e27\u0e21\u0e2d\u0e22\u0e39\u0e48\u0e43\u0e19\u0e01\u0e2d\u0e07\u0e17\u0e38\u0e19\u0e41\u0e08\u0e47\u0e04\u0e1e\u0e2d\u0e15 \u0e43\u0e19\u0e23\u0e2d\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a \u0e41\u0e08\u0e47\u0e04\u0e1e\u0e2d\u0e15\u0e08\u0e30\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e23\u0e31\u0e1a\u0e23\u0e32\u0e07\u0e27\u0e31\u0e25\u0e44\u0e14\u0e49",
"bonus_rounds_p5": "\u0e01\u0e32\u0e23\u0e17\u0e35\u0e48\u0e1c\u0e39\u0e49\u0e40\u0e25\u0e48\u0e19\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e2d\u0e32\u0e08\u0e2a\u0e48\u0e07\u0e1c\u0e25\u0e43\u0e2b\u0e49\u0e23\u0e2d\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a\u0e17\u0e35\u0e48\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e2d\u0e22\u0e39\u0e48\u0e16\u0e39\u0e01\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01 \u0e43\u0e19\u0e01\u0e23\u0e13\u0e35\u0e19\u0e35\u0e49 \u0e08\u0e33\u0e19\u0e27\u0e19\u0e23\u0e2d\u0e1a\u0e42\u0e1a\u0e19\u0e31\u0e2a\u0e08\u0e30\u0e25\u0e14\u0e25\u0e07\u0e41\u0e25\u0e30\u0e22\u0e2d\u0e14\u0e04\u0e07\u0e40\u0e2b\u0e25\u0e37\u0e2d\u0e02\u0e2d\u0e07\u0e1c\u0e39\u0e49\u0e40\u0e25\u0e48\u0e19\u0e08\u0e30\u0e22\u0e31\u0e07\u0e04\u0e07\u0e40\u0e17\u0e48\u0e32\u0e40\u0e14\u0e34\u0e21",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'การเดิมพันเปิดใช้งานแล้ว',
};
langArr.tl = {
'loading': 'Naglo-load',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
"bonus_rounds": "Mga Round ng Bonus",
"bonus_rounds_p1": "Ang isang manlalaro ay maaaring random na iginawad ng mga bonus round (ibig sabihin, libreng taya). Isang popup graphic ang lalabas sa screen na nag-aabiso sa manlalaro tungkol sa mga iginawad na round ng bonus, bilang ng mga taya at halaga sa bawat taya.",
"bonus_rounds_p2": "Kapag natanggap na sila, maaaring piliin ng manlalaro kung kailan sisimulan ang bonus round. Kapag nagsimula na ang mga round ng bonus, ang awtomatikong taya na may tinukoy na stake ay ilalagay, at nasa player na mag-cashout sa anumang oras.",
"bonus_rounds_p3": "Maaaring magkaroon ng limitasyon sa oras ang mga round ng bonus, pagkatapos ay magiging walang bisa at hindi na magagamit ang mga ito. Ang panahon ng bisa, kung mayroon man, ay ipinapakita sa ilalim ng bonus round.",
"bonus_rounds_p4": "Ang bonus round ay hindi nag-aambag sa isang jackpot fund. Sa panahon ng bonus round hindi mapanalunan ang jackpot.",
"bonus_rounds_p5": "Ang katamaran ng manlalaro ay maaaring magresulta sa pagkansela ng isang aktibong round ng bonus. Sa kasong ito, ang bilang ng mga round ng bonus ay nabawasan at ang balanse ng manlalaro ay mananatiling pareho.",
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Taya ay aktibo na',
};
langArr.xe = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Bonus round does not contribute to a jackpot fund. During bonus round jackpot cannot be won.',
'bonus_rounds_p5': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Bet already active',
};
langArr.da = {
'loading': 'Indlæser',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonusrunder',
'bonus_rounds_p1': 'En spiller kan tilfældigt tildeles bonusrunder (dvs. gratis væddemål). En pop op-grafik vises på skærmen, der informerer spilleren om tildelte bonusrunder, antal indsatser og beløb pr.',
'bonus_rounds_p2': 'Når de er tildelt, kan spilleren vælge, hvornår bonusrunden skal starte. Når bonusrunderne er startet, placeres automatisk væddemål med den specificerede indsats, og det er op til spilleren at udbetale på ethvert givet tidspunkt.',
'bonus_rounds_p3': 'Bonusrunder kan have en tidsbegrænsning, hvorefter de bliver ugyldige og ubrugelige. Gyldighedsperioden, hvis nogen, vises under bonusrunden.',
'bonus_rounds_p4': 'Bonusrunde bidrager ikke til en jackpotfond. Under bonusrunden kan jackpot ikke vindes.',
'bonus_rounds_p5': 'Spillerens tomgang kan resultere i annullering af en aktiv bonusrunde. I dette tilfælde reduceres antallet af bonusrunder, og spillerens saldo forbliver den samme.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Væddemål allerede aktiv',
};
langArr.nl = {
'loading': 'Bezig met laden',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Soccer Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Soccer Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonusrondes',
'bonus_rounds_p1': 'Een speler kan willekeurig worden beloond met bonusrondes (d.w.z. gratis weddenschappen). Er verschijnt een pop-upafbeelding op het scherm die de speler informeert over de toegekende bonusrondes, het aantal weddenschappen en het bedrag per weddenschap.',
'bonus_rounds_p2': 'Zodra ze zijn toegekend, kan de speler kiezen wanneer hij de bonusronde wil starten. Zodra de bonusrondes zijn gestart, wordt een automatische weddenschap met de opgegeven inzet geplaatst en is het aan de speler om op elk gewenst moment uit te betalen.',
'bonus_rounds_p3': 'Bonusrondes kunnen een tijdslimiet hebben, waarna ze ongeldig en onbruikbaar worden. De geldigheidsperiode, indien van toepassing, wordt weergegeven onder de bonusronde.',
'bonus_rounds_p4': 'Bonusronde draagt ​​niet bij aan een jackpotfonds. Tijdens de bonusronde kan er geen jackpot worden gewonnen.',
'bonus_rounds_p5': 'Het nietsdoen van spelers kan resulteren in de annulering van een actieve bonusronde. In dit geval wordt het aantal bonusrondes verlaagd en blijft het saldo van de speler hetzelfde.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user',
'bet_already_active': 'Inzet al actief',
};
