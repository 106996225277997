/* eslint-disable import/no-anonymous-default-export */
export default {
    "us": {
      "gameServers": "https://srv.rush.elbetdev.com:8003"
    },
    "retail": {
      "gameServers": "https://srv.rush.elbetdev.com:8003"
    },
    "default": {
      "gameServers": "https://srv.rush.elbetdev.com:8003"
    }
  }