import axios from 'axios';
import { urlParams } from '../get-url-params';
import config from '../../config/config';
import { acceptedRegion } from '../helpers/accepted-regions';

const region = acceptedRegion(urlParams.get('region')) ? urlParams.get('region') : 'default';
export const apiClient = axios.create({
  
  baseURL: config[region].gameServers,
  //headers: 
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(function (config) {
  config.headers = {
    'Content-Type': 'application/json',
  }

  // Add default `game_id` to the request body if it's not already included
  if (!config.data) {
    config.data = {};
  }
  if (typeof config.data === 'object' && !config.data.game_id) {
    config.data.game_id = Number(process.env.REACT_APP_GAME_ID) || 105;
  }


  return config;
});

apiClient.interceptors.response.use(undefined, error => {
  if (!error.response) {
    return new Promise((res, rej) => {
      rej(error);
    });
  } else {
    const { response } = error;
    const { data, status } = response;

    return new Promise((res, rej) => {
      rej({
        status,
        data
      });
    });
  }
})

export default apiClient;
